// Set the session in the local storage
export const setSession = (token: string, expiry: string, email: string, firstName: string, lastName: string): void => {
  localStorage.setItem("token", token);
  localStorage.setItem("expiry", expiry);
  localStorage.setItem("email", email);
  localStorage.setItem("firstName", firstName);
  localStorage.setItem("lastName", lastName);
};

// Clear the session from the local storage
export const clearSession = (): void => {
  localStorage.removeItem("token");
  localStorage.removeItem("expiry");
  localStorage.removeItem("email");
  localStorage.removeItem("firstName");
  localStorage.removeItem("lastName");
};

// Checks if the session is valid (locally) according to the expiration time
export const isSessionValid = (): boolean => {
  const expiry = localStorage.getItem("expiry");
  if (expiry) {
    return +new Date(expiry) > +new Date();
  }
  return false;
};

export const getToken = () => {   
  return {
    token: localStorage.getItem("token"),
    expiry: localStorage.getItem("expiry"),
    email: localStorage.getItem("email"),
    firstName: localStorage.getItem("firstName"),
    lastName: localStorage.getItem("lastName")
  }
}

// Creates the authorization header using the bearer token
export const getAuthHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem("token")}`,
});
