import React, { Fragment } from "react";
import { isSessionValid, getToken } from "../../session";
import { Container, Row, Col, Alert, Button, Spinner } from "reactstrap";
import { GeneratedSystemRequirements } from "../products/SystemRequirements";
import axios from "axios";
import { IProductSpecs } from "../products/Product";
import * as debug from "debug";

const X50Specs: IProductSpecs = {
    "product": {
        "id": "X50v24",
        "name": "TSE X50",
        "currentVersion": "2.4.8",
        "releaseDate": "April 18 2018",
        "imageUrl": "/assets/images/products/X50box.png"
    },
    "license": {
        "isDownload": true,
        "url": "https://store.tseaudio.com/api/licensegen"
    },
    "downloadLinks": {
        "windows": "https://windows",
        "macOS": "https://macos"
    },
    "requirements": {
        "windows": {
            "cpu": "Intel Core 2",
            "operatingSystem": "Windows 7",
            "daw": "Pro Tools 11 or VST compatible",
            "pluginFormats": ["VST2", "AAX64"]
        },
        "macOS": {
            "cpu": "Intel Core 2",
            "operatingSystem": "macOS 10.9",
            "daw": "Logic Pro X, Pro Tools 11 or VST compatible",
            "pluginFormats": ["AudioUnit", "VST2", "AAX64"]
        }
    },
};

const Precise808Specs: IProductSpecs = {
    product: {
        id: "Precise808",
        name: "Precise 808",
        currentVersion: "1.0.0",
        releaseDate: "May 1st 2020",
        // "imageUrl": "/assets/images/products/X50box.png"
    },
    license: {
        isDownload: false,
        url: "https://licensegen"
    },
    downloadLinks: {
        windows: "https://windows",
        macOS: "https://macos",
        linux: "https://linux"
    },
    requirements: {
        windows: {
            cpu: "Intel Core i5",
            operatingSystem: "Windows 7",
            daw: "Pro Tools 11 or VST compatible",
            pluginFormats: ["VST2", "AAX64"]
        },
        macOS: {
            cpu: "Intel Core i5",
            operatingSystem: "macOS 10.11",
            daw: "Logic Pro X, Pro Tools 11 or VST compatible",
            pluginFormats: ["AudioUnit", "VST2", "AAX64"]
        },
        linux: {
            cpu: "Intel Core i5",
            operatingSystem: "Ubuntu 16.10",
            daw: "Reaper",
            pluginFormats: ["VST2", "Ladspa"]
        }
    },
};

const NoAccess = () => {
  return (
    <Container style={{ marginTop: "2em" }}>
      <Row>
        <Col>
          <Alert color="danger">You need to sign in to access this resource!</Alert>
        </Col>
      </Row>
    </Container>
  );
};

const downloadLicense = async (specs: IProductSpecs) => {
  try {
    if (specs.license && specs.license.isDownload) {
      await axios
        .get<any>(specs.license.url, {
          headers: {
            Authorization: `Bearer ${getToken().token}`,
          },
          responseType: "blob",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `X50v2_${getToken().email}.xlic`);
          document.body.appendChild(link);
          link.click();
        });
    }
  } catch (error) {
    debug.log("error fetching license file:", error);
  }
};

const describeLinkContent = (address: string) => {
  // if ()
};


const ProductEntry = (specs: IProductSpecs) => {
  const productName = specs.product.name;
  const productVersion = specs.product.currentVersion;
  const productReleaseDate = specs.product.releaseDate;
  const productImageLink = specs.product.imageUrl || "";

  const systemRequirements = specs.requirements;
  const downloadLinks = specs.downloadLinks;

  return (
    <Row style={{ backgroundColor: "#303030", paddingTop: "0em", paddingBottom: "0em" }}>

      <Col className="bottom-bordered">

        <Row style={{ backgroundColor: "#0000001f", padding: "0.5em" }}>

          <Col lg="2" className="text-center" style={{ paddingTop: "1em" }}>
            <h4 className="tse-orange bottom-bordered">{productName}</h4>
            {/* Place product image */
            productImageLink.length > 0 && (
              <img
                src={productImageLink}
                alt="Product box"
                width="100%"
                style={{
                  paddingTop: "10px",
                  minWidth: "50px",
                  maxWidth: "300px",
                }}
              />
            )}

          </Col>

          <Col id="">
            <Row style={{ margin: "0.5em", paddingTop: "1em", backgroundColor: "#0000001f" }}>
              <Col>
                <p>
                  <span className="details">
                    <i className="fas fa-chevron-circle-up tse-orange" /> Current Version: <b>{productVersion}</b>
                  </span>
                  <span className="details" style={{ marginLeft: "2em" }}>
                    <i className="fas fa-calendar-alt tse-orange" /> Released: {productReleaseDate}
                  </span>
                </p>
              </Col>
            </Row>

            {specs.license && (
              <Row style={{ margin: "0.5em", paddingTop: "1em", paddingBottom: "1em", backgroundColor: "#0000001f" }}>
                <Col>
                  <h6>
                    <i className="fas fa-unlock tse-orange" /> Product License{" "}
                  </h6>
                  <div>
                    <Button className="btn-warning" onClick={() => downloadLicense(specs)}>
                      <span style={{ color: "#000" }}>LICENSE</span> {productName.toUpperCase()}
                    </Button>
                  </div>
                </Col>
              </Row>
            )}

            {downloadLinks && (
              <Row className="" style={{ margin: "0.5em", paddingTop: "1em", paddingBottom: "1em", backgroundColor: "#0000001f" }}>
                <Col>
                  <h6 className="">
                    <i className="fas fa-file-download tse-orange" /> Software Download
                  </h6>

                  <Row>
                    {downloadLinks.windows && (
                      <Col md="">
                        <i className="fab fa-windows" /> <a href={downloadLinks.windows}>Windows Installer</a>{" "}
                      </Col>
                    )}

                    {downloadLinks.macOS && (
                      <Col md="">
                        <i className="fab fa-apple" /> <a href={downloadLinks.macOS}>macOS Installer</a>
                      </Col>
                    )}

                    {downloadLinks.linux && (
                      <Col md="">
                        <i className="fab fa-ubuntu" /> <a href={downloadLinks.linux}>Linux Installer</a>
                      </Col>
                    )}

                    <Col />
                    <Col />
                  </Row>
                </Col>
              </Row>
            )}

            {/* List minimum system requirements */
            systemRequirements && (
              <Row style={{ margin: "0.5em", paddingTop: "1em", backgroundColor: "#0000001f" }}>
                <Col>
                  <GeneratedSystemRequirements Requirements={systemRequirements} />
                </Col>
              </Row>
            )}

          </Col>

        </Row>

      </Col>

    </Row>
  );
};

interface ListProductsProps {
  myProducts: IProductSpecs[] | null;
}

const ListProducts: React.FC<ListProductsProps> = ({ myProducts }) => {
  // const myProducts: IProductSpecs[] = [ X50Specs, Precise808Specs ];
  return (
    <div>
      {myProducts && myProducts.length === 0 && (
        <Row>
          <Col className="text-center">
            <div>
              <Spinner />
              <br />
              <p>One moment please, we are collecting your licenses ...</p>
            </div>
          </Col>
        </Row>
      )}

      {myProducts && myProducts.map((value, index) => {
        return <div key={index}>{ProductEntry(value)}</div>;
      })}
    </div>
  );
};

interface IAccount {
  products: IProductSpecs[] | null;
  gotResponse: boolean;
}

class Account extends React.Component<{}, IAccount> {
  public constructor(props: any) {
    super(props);

    this.state = {
      products: [],
      gotResponse: false
    };
  }

  private getCachedStateFromLocalStorage() {
    const cache = localStorage.getItem('products');
    const staleness = localStorage.getItem('staleness');

    if (cache && staleness) {
      let then = new Date(parseInt(staleness, 10));
      let now = new Date();

      if ((now.getTime() - then.getTime()) / 1000.0 > 900.0) {
        return null;
      }

      return JSON.parse(cache);
    }

    return null;
  }



  public async componentDidMount() {
    const cache = this.getCachedStateFromLocalStorage();
    if (cache) {
      this.setState({products: cache, gotResponse: true});
    } else {
      const jwtPayload = getToken();
      const result = await axios.get<any>("https://store.tseaudio.com/api/users/profile", {
        headers: {
          Authorization: `Bearer ${jwtPayload.token}`,
        },
        data: {
          email: jwtPayload.email,
        },
      });

      if (result.status === 200) {
        this.setState({
          products: result.data.products,
          gotResponse: true
        });

        localStorage.setItem('products', JSON.stringify(result.data.products));
        localStorage.setItem('staleness', (Date.now()).toString())

      } else {
        this.setState({ ...this.state, products: null });
      }
    }
    // const jwtPayload = getToken();
    // const result = await axios.get<any>("https://store.tseaudio.com/api/users/profile", {
    //   headers: {
    //     Authorization: `Bearer ${jwtPayload.token}`,
    //   },
    //   data: {
    //     email: jwtPayload.email,
    //   },
    // });

    // if (result.status === 200) {
    //   this.setState({
    //     products: result.data.products,
    //     gotResponse: true
    //   });
    // } else {
    //   this.setState({...this.state, products: null });
    // }

    // this.setState({products: [X50Specs]})
    // console.log(JSON.stringify(result.data));
  }

  public render() {
    if (!isSessionValid()) {
      return <NoAccess />;
    }

    const userInfo = getToken();
    const hasProducts = this.state.products && this.state.products.length > 0;
    const gotResponse = this.state.gotResponse;

    return (
      <Fragment>
        <Row className="content">
          <Col>
            <Row style={{ paddingTop: "1em", paddingBottom: "1em" }}>

              <Col>
                <h3 className="tse-orange">My Products</h3>
                <h6>
                  <i className="fas fa-user tse-orange" /> {`${userInfo.firstName} ${userInfo.lastName}`}
                  <i className="fas fa-envelope tse-orange" style={{ paddingLeft: "1em" }} /> {userInfo.email}
                </h6>

                { gotResponse && !hasProducts &&
                  <div style={{ padding: "2em", maxWidth: "600px"}}>
                    <Alert color="danger">We could not find any products linked to your account.
                    Did you maybe ask us to transfer your license(s) to a new user?<br/>
                    If you are sure this is incorrect, please contact us if this doesn't resolve after a page refresh!
                    </Alert>
                  </div>
                }
              </Col>

              <Col lg="1" />
              </Row>

              {/*==============================================================================================*/}

              { (!gotResponse || hasProducts) &&
                <ListProducts myProducts={this.state.products} />
              }

          </Col>
        </Row>


      </Fragment>
    );
  }
}

export default Account;
