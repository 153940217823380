import React, { Fragment } from "react";

import {
    Row, Col, UncontrolledCollapse, CardBody, Card
} from "reactstrap";
import { useState } from "react";


// const createQuestionWAnswer = (question: string) => {
const QuestionWAnswer = (props: any) => {
    const rnd = Math.floor(Math.random() * 10000);
    const questionId = "toggler" + String(rnd);
    const togglerId = `#${questionId}`    
    const [mouseIsOver, updateMousePos] = useState(false);

    return (
        <div>
            {/* <a href="#" id="toggler"> */}
            <div
                id={questionId} 
                onMouseOver={() => updateMousePos(true) }
                onMouseLeave={() => updateMousePos(false) }
                style={{ color: mouseIsOver ? "#CE9100" : "#fff" }}
            >
                { props.question }
            </div>

            <UncontrolledCollapse toggler={togglerId}>
                <Card>
                    <CardBody>
                        { props.answer ? props.answer : "Missing answer" }
                    </CardBody>
                </Card>
            </UncontrolledCollapse>
        </div>
    );
}



class FAQ extends React.Component {
    public render() {
        return (
            <Fragment>
                <Row className="content" style={{ paddingTop: "2em", paddingBottom: "2em" }}>
                    <Col>
                        <h2 className="tse-orange">Frequently Asked Questions</h2>
                    </Col>
                </Row>
                <Row className="content" style={{ paddingBottom: "2em", minHeight: "30em"}}>
                    <Col>
                        <div style={{ marginBottom: "2em" }}>
                            <h5 className="tse-orange">General</h5>

                            <QuestionWAnswer
                                question="When will you run your next discount?"
                                answer={(
                                    <div>                                    
                                        Please check our <a href="https://www.facebook.com/tseaudio">Facebook</a> page 
                                        for announcements.
                                        We usually do not plan this in advance. <br/>
                                        Tip: If you like our FB page you will receive new announcements from us in your news feed.
                                    </div>
                                    )                                
                                }/>

                            <QuestionWAnswer
                                question="Do you offer student discounts?"
                                answer={(
                                    <div>
                                        Yes, we offer a 20% student discount to all students who can provide us a 
                                        scan of their valid student ID card. <br/>Please give us up to a week to process 
                                        your student discount request.
                                    </div>
                                )
                                } />                                

                            {/* <QuestionWAnswer
                                question="When will your next plugin come out?"
                                answer="When we make something new. "
                            /> */}

                        </div>


                        <div>
                            <h5 className="tse-orange">TSE X50</h5>

                            <QuestionWAnswer 
                                question="Where can I download the full version software, or updates?"
                                answer={(
                                    <div>
                                        Click on the "My Account" link in the website's navigation menu and
                                        enter your credentials to access our resource portal. <br/>Your username is the e-mail address 
                                        you used when purchasing the software. If you cannot remember your password use the
                                        "Forgot your password?" link below the login button to reset it.
                                    </div>
                                )}
                            />

                            <QuestionWAnswer
                                question="I have purchased TSE X50, but I received no link to set a password for my account?"
                                answer={(
                                    <div>
                                        Please check your spam folder first and if no success click on the "My Account" link in the website's navigation menu and
                                        use the "Forgot your password?" link below the login button to receive a
                                        reset-password link from our system. <br/><br/>Please use the same e-mail address that 
                                        you used when completing the FastSpring purchase process.
                                    </div>
                                )}
                            />                            
                        </div>                    

                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default FAQ;