import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import { GeneratedSystemRequirements } from "./SystemRequirements";
import { IProductRequirements } from "./Product";
import DonationForm from "./Donate";
import ProductJumbotron from "./ProductJumbotron";
import { Link } from "react-router-dom";
import DownloadButton from "../buttons/downloadButton";

const minimumRequirements: IProductRequirements = {
    windows: {
        cpu: "Intel Core 2 or newer",
        operatingSystem: "Windows 7",
        daw: "32/64 bit compatible",
        pluginFormats: ["VST2"],
    },
    macOS: {
        cpu: "Intel Core 2 or newer",
        operatingSystem: "OS X 10.7",
        daw: "32/64 bit compatible",
        pluginFormats: ["VST2", "AudioUnit"],
    },
};



const TSE808 = () => {
    return (
        <Fragment>
            <Row className="content">
                <Col lg="12">
                    <ProductJumbotron
                        freeware={true}
                        bottomBordered={false}
                        productTitle="TSE808"
                        productSubtitle="Guitar Overdrive Plugin"
                        productVersion="2.0.1"
                        productImageUrl="/assets/images/vst_transparent/808.png"
                        productDescription={
                            <div>
                                The <b className="tse-orange">TSE808</b> is emulating a famous tube screamer pedal.<br />
                            Read the included manual for more info.
                        </div>}
                    />
                </Col>
            </Row>

            <Row>
                <Col lg="12">
                    {/*==============================================================================================*/}
                    {/* Product resources */}
                    <Row className="content" style={{ paddingTop: "0em", paddingBottom: "0em" }}>

                        <Col lg="3" style={{ margin: "10px" }}>
                            <Row>
                                <Col>
                                    <h5 className="tse-orange"><i className="fas fa-download" /> Download TSE808</h5>
                                </Col>
                            </Row>

                            <Row style={{ marginTop: "1em" }}>
                                <Col className="text-center">
                                    <Row>
                                        <Col>
                                            <DownloadButton
                                                downloadType="windows"
                                                titleFirst="Windows x86"
                                                titleEnd="Plugin"
                                                to="/files/TSE808_win32.zip"
                                            />
                                            <DownloadButton
                                                downloadType="windows"
                                                titleFirst="Windows x64"
                                                titleEnd="Plugin"
                                                to="/files/TSE808_win64.zip"
                                            />
                                            <DownloadButton
                                                downloadType="apple"
                                                titleFirst="macOS"
                                                titleEnd="Plugin"
                                                to="/files/TSE808_OSX.zip"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col style={{ margin: "10px" }}>
                            <h5 className="tse-orange"><i className="fas fa-cog" /> System Requirements </h5>
                            <GeneratedSystemRequirements Requirements={minimumRequirements} showTitle={false} />
                        </Col>

                        <Col style={{ margin: "10px" }}>
                            {/* <h5 className="tse-orange"><i className="fas fa-donate" /> Support future development</h5>
                            {DonationForm()} */}
                        </Col>

                    </Row>
                </Col>


            </Row>


        </Fragment>
    );
};





const TSEX30 = () => {
    return (
        <Fragment>
            <Row className="content top-bordered">
                <Col lg="12">
                    <ProductJumbotron
                        freeware={true}
                        bottomBordered={false}
                        productTitle="TSE X30"
                        productSubtitle="Tube Pre-Amp Plugin"
                        productVersion="1.6"
                        productImageUrl="/assets/images/vst_transparent/x30.png"
                        productDescription={
                            <div>
                                The <b className="tse-orange">X30</b> is emulating a high gain german pre-amp.<br /><br />
                            The technology used in this software was unfortunately outdated a long time ago (2009) and an
                            updated version with improved modeling precision is included in
                            the <Link to="/software/X50#">TSE X50 v2</Link>
                            </div>}
                    />
                </Col>
            </Row>

            <Row>
                <Col lg="12">
                    {/*==============================================================================================*/}
                    {/* Product resources */}
                    <Row className="content" style={{ paddingTop: "0em", paddingBottom: "1em" }}>

                        <Col lg="3" style={{ margin: "10px" }}>
                            <Row>
                                <Col>
                                    <h5 className="tse-orange"><i className="fas fa-download" /> Download TSE X30</h5>
                                </Col>
                            </Row>

                            <Row style={{ marginTop: "1em" }}>
                                <Col lg="3" className="text-center">
                                    <Row>
                                        <Col>
                                            <DownloadButton
                                                downloadType="windows"
                                                titleFirst="Windows x86"
                                                titleEnd="Plugin"
                                                to="/files/TSE_X30v1.6.zip"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col style={{ margin: "10px" }}>
                            <h5 className="tse-orange"><i className="fas fa-cog" /> System Requirements </h5>
                            <GeneratedSystemRequirements Requirements={minimumRequirements} showTitle={false} />
                        </Col>

                        <Col style={{ margin: "10px" }}>
                            <h5 className="tse-orange"><i className="fas fa-donate" /> Support future development</h5>
                            {DonationForm()}
                        </Col>

                        <Col lg="1" />
                    </Row>

                </Col>
            </Row>
        </Fragment>
    );
};





const LegacyProducts = () => {
    return (
        <Fragment>
            <TSE808 />
            <TSEX30 />
        </Fragment>
    );
};

export default LegacyProducts;
