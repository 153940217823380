import React from "react";
import { Col, Row, Badge } from "reactstrap";

interface IProductJumbotron {
  productTitle: string;
  productSubtitle: string | null;
  productVersion: string;
  productDescription: any;
  productImageUrl?: string;
  imageObject?: any;
  bottomBordered?: boolean;
  freeware?: boolean;
}

const ProductJumbotron = (options: IProductJumbotron) => {
  return (
      <Row
        className={"" + (options.bottomBordered ? "bottom-bordered" : "")}
        style={{
          paddingTop: "2em",
          paddingBottom: "2em",
        }}>

        <Col lg="12">
          <Row>
            <Col>
              <Row>
                <Col>
                  <h1 className="display-4 tse-orange">{" " + options.productTitle}</h1>
                </Col>
              </Row>

              <Row>
                <Col className="">
                  {options.productSubtitle && <h4>{options.productSubtitle}</h4>}
                  <h6>
                    Version <b style={{ color: "#fff" }}>{options.productVersion}</b>
                  </h6>
                </Col>

                <Col className="text-left">
                  {options.freeware && (
                    <h5>
                      <Badge color="dark">
                        <i className="fas fa-dove" /> Freeware
                      </Badge>
                    </h5>
                  )}
                </Col>
              </Row>

              <div className="lead" style={{ paddingTop: "1em" }}>
                {options.productDescription}
              </div>
            </Col>


            {options.productImageUrl && (
              <Col lg="6" className="text-center" style={{ paddingTop: "1em" }}>
                <img className="shadow rounded" src={options.productImageUrl} style={{ maxWidth: "90%" }} />
              </Col>
            )}

            {options.imageObject && (
              <Col lg="6" className="" style={{ paddingTop: "1em" }}>
                {options.imageObject}
              </Col>
            )}
          </Row>
        </Col>

      </Row>
  );
};

export default ProductJumbotron;
