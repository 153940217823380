import React from "react";
import { Table } from "reactstrap";
import { IProductRequirements } from "./Product";

interface SystemRequirementsProps {
  Requirements: IProductRequirements;
  showTitle?: boolean;
}

export const GeneratedSystemRequirements: React.FC<SystemRequirementsProps> = ({ Requirements, showTitle = true }) => {
  const hasDAW: boolean =
    (Requirements.windows && Requirements.windows.daw) !== undefined ||
    (Requirements.macOS && Requirements.macOS.daw) !== undefined ||
    (Requirements.linux && Requirements.linux.daw) !== undefined;

  return (
    <div>
      {showTitle && (
        <h6 className="">
          <i className="fas fa-info-circle tse-orange" /> Minimum System Requirements
        </h6>
      )}
      <Table borderless style={{ fontSize: "14px" }}>
        <thead className="bottom-bordered">
          <tr>
            <th />
            {Requirements.windows && (
              <th>
                <i className="fab fa-windows" /> Windows
              </th>
            )}
            {Requirements.macOS && (
              <th>
                <i className="fab fa-apple" /> macOS
              </th>
            )}
            {Requirements.linux && (
              <th>
                <i className="fab fa-ubuntu" /> Linux
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          <tr>
            <th scope="row">
              <i className="fas fa-microchip" /> CPU
            </th>
            {Requirements.windows && <th> {Requirements.windows.cpu} </th>}
            {Requirements.macOS && <th> {Requirements.macOS.cpu} </th>}
            {Requirements.linux && <th> {Requirements.linux.cpu} </th>}
          </tr>

          <tr>
            <th scope="row">
              <i className="fas fa-history" /> OS
            </th>
            {Requirements.windows && <th> {Requirements.windows.operatingSystem} </th>}
            {Requirements.macOS && <th> {Requirements.macOS.operatingSystem} </th>}
            {Requirements.linux && <th> {Requirements.linux.operatingSystem} </th>}
          </tr>

          {hasDAW && (
            <tr>
              <th scope="row">
                <i className="fas fa-file-audio" /> DAW
              </th>
              {Requirements.windows && <th> {Requirements.windows.daw} </th>}
              {Requirements.macOS && <th> {Requirements.macOS.daw} </th>}
              {Requirements.linux && <th> {Requirements.linux.daw} </th>}
            </tr>
          )}

          {hasDAW && (
            <tr>
              <th scope="row">
                <i className="fas fa-plug" /> Plugin Formats
              </th>
              {Requirements.windows && <th> {(Requirements.windows.pluginFormats || []).join(", ")} </th>}
              {Requirements.macOS && <th> {(Requirements.macOS.pluginFormats || []).join(", ")} </th>}
              {Requirements.linux && <th> {(Requirements.linux.pluginFormats || []).join(", ")} </th>}
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

const SystemRequirements = (props: any) => {
  return (
    <Table borderless>
      <thead className="bottom-bordered">
        <tr>
          <th />
          <th>Windows</th>
          <th>macOS</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <th scope="row">CPU</th>
          <td>Intel Core 2</td>
          <td>Intel Core 2</td>
        </tr>
        <tr>
          <th scope="row">Operating System</th>
          <td>Windows 7</td>
          <td>macOS 10.9</td>
        </tr>
        <tr>
          <th scope="row">DAW</th>
          <td>Pro Tools 11 or VST compatible</td>
          <td>Logic Pro X, Pro Tools 11 or VST compatible</td>
        </tr>
        <tr>
          <th scope="row">Plugin Formats</th>
          <td>VST2, AAX64</td>
          <td>AudioUnit, VST2, AAX64</td>
        </tr>

        {props.extraRow ? props.extraRow : ""}
      </tbody>
    </Table>
  );
};

export default SystemRequirements;
