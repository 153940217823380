import React from "react";
import { Button } from "reactstrap";

interface IDownloadButton {
    downloadType?: "apple" | "windows" | "linux" | "manual";
    titleFirst: string;
    titleEnd: string;
    to: string;
    onClick?: () => void;
}

const DownloadButton = (options: IDownloadButton) => {
    const buttonStyle: React.CSSProperties = {
        marginBottom: "10px",
        /*marginRight: "10px",*/
        height: "40px",
        minWidth: "200px",
        width: "90%",
        color: "#000000",
        textAlign: "right",
        // fontSize: "16px",
    };

    let iconType = "";

    if (options.downloadType) {
        switch (options.downloadType) {
            case "apple":
                iconType = "fab fa-apple";
                break;
            case "windows":
                iconType = "fab fa-windows";
                break;
            case "linux":
                iconType = "fab fa-linux";
                break;
            case "manual":
                iconType = "fas fa-book";
                break;
        }
    }

    return (
        <React.Fragment>
            <a href={options.to}>
                <Button className="btn-warning" size="md" style={buttonStyle} onClick={options.onClick ? options.onClick : undefined}>
                    <i className={iconType} /> {options.titleFirst}{" "}
                    <span style={{ color: "#ffffff" }}>
                        <b>{options.titleEnd}</b>
                    </span>
                </Button>
            </a>
            {/* <Link to={options.to} download target="_self"> */}
            {/* </Link> */}
        </React.Fragment>);
};

export default DownloadButton;