import React, { Fragment } from 'react';
import { UncontrolledCarousel, Button } from 'reactstrap';
import debug from "debug";


function slideContent(content:string[] = []) {
    const divided = content.map((str: string, index: number) => {
        debug.log("debug:", str, index);
        return <Fragment key={ index }>{str}<br/></Fragment>
    }
    
    );

    return (
        
        <Fragment key={ Math.round(10000 * Math.random()) }>
        {divided}
        <br/>
            <Button className="btn-warning" href="/software/X50">LEARN MORE</Button>
        
        </Fragment>
    );
}

const getItems = (showText: boolean = true) => {
    if (showText) {
        return [
            {
                src: '/assets/images/carousel/x302_photo.jpg',
                altText: 'TSE X30',
                header: 'TSE X30 Guitar Amplifier',
                caption: slideContent(["Included in the X50 v2 suite"]),
                // caption: slideContent(
                //     ["The original X30 was introduced back in 2009, it is also TSE Audio's first" ,
                //     "offical product. This is our completely re-modeled 5 year anniversary model."]
                // ),
                key: '1'
            },
            {
                src: '/assets/images/carousel/x50_photo.jpg',
                altText: 'TSE X50',
                header: 'TSE X50 Guitar Amplifier',
                caption: slideContent(["Included in the X50 v2 suite"]),
                // caption: slideContent(
                //     ["The X50 is a digital emulation of a famous US guitar amp. Because two almost similar amps were",
                //     "made both red channels are modeled. The green channel is modeled after the first amp."]
                // ),
                key: '2'
            },
            {
                src: '/assets/images/carousel/x50pa_photo.jpg',
                altText: 'TSE X50 Power Amp',
                header: 'TSE X50 Power Amp',
                caption: slideContent(["Included in the X50 v2 suite"]),
                // caption: slideContent(
                //     ["The X50 is a digital emulation of a famous US guitar amp. Because two almost similar amps were \
                //     made both red channels are modeled. The green channel is modeled after the first amp."]
                // ),
                key: '3'
            },
            {
                src: '/assets/images/carousel/FXrack_photo.jpg',
                altText: 'TSE X50 FX Rack',
                header: 'FX Rack',
                caption: slideContent(["Included in the X50 v2 suite"]),
                // caption: slideContent(
                //     ["Bacon ipsum dolor amet pancetta turducken prosciutto filet mignon shankle.\
                //         Leberkas pork sausage, pancetta meatloaf pork chop ground round boudin kevin t-bone.. \
                //         Bacon ipsum dolor amet pancetta turducken prosciutto filet mignon shankle. \
                //         Leberkas pork sausage, pancetta meatloaf pork chop ground round boudin kevin t-bone.. \
                //     "]
                // ),
                key: '4'
            },
            {
                src: '/assets/images/carousel/stomp_photo.jpg',
                altText: 'TSE X50 Stomp Pedals',
                header: 'Pedal Board',
                caption: slideContent(["Included in the X50 v2 suite"]),
                // caption: slideContent(
                //     ["Bacon ipsum dolor amet pancetta turducken prosciutto filet mignon shankle. \
                //     Leberkas pork sausage, pancetta meatloaf pork chop ground round boudin kevin t-bone.."]
                // ),
                key: '5'
            },
            {
                src: '/assets/images/carousel/FIRE_photo.jpg',
                altText: 'TSE X50 FIRE IR Loader',
                header: 'FIRE! - Impulse Responses',
                caption: slideContent(["Included in the X50 v2 suite"]),
                // caption: slideContent(
                //     ["Bacon ipsum dolor amet pancetta turducken prosciutto filet mignon shankle. \
                //     Leberkas pork sausage, pancetta meatloaf pork chop ground round boudin kevin t-bone.."]
                // ),
                key: '6'
            }
        ];
    }

    return [
        {
            caption: "",
            src: '/assets/images/carousel/x50_photo.jpg',
            key: '1'
        },
        {
            caption: "",
            src: '/assets/images/carousel/x302_photo.jpg',
            key: '2'
        },
        {
            caption: "",
            src: '/assets/images/carousel/x50pa_photo.jpg',
            key: '3'
        },
        {
            caption: "",
            src: '/assets/images/carousel/FXrack_photo.jpg',
            key: '4'
        },
        {
            caption: "",
            src: '/assets/images/carousel/stomp_photo.jpg',
            key: '5'
        },
        {
            caption: "",
            src: '/assets/images/carousel/FIRE_photo.jpg',
            key: '6'
        }
    ];
}



const X50Carousel = (props: any) => {
    return (
        <UncontrolledCarousel
            items={getItems(props.showText)}
            style={{ width: "100%" }}
            {...props} />
    );
}

export default X50Carousel;