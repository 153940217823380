import React, { Fragment } from "react";
import X50Carousel from "../products/X50Carousel";
import {
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import IRBrands from "../irbrands";
import { NavLink as RRNavLink, useHistory } from "react-router-dom";
import { clearSession, isSessionValid } from "../../session";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import LoginModal from "../auth/loginmodal";
import useWindowSize from "../../hooks/use-window-size";
import { useLocation } from "react-router-dom";


// This is the width at which the navbar will transition from floating to non-floating
const expandWidth = 768;




const TSELogo = (props: any) => {
  const scale: number = 0.8;
  return (
    <Logo
      fill="#BCBDBE"
      style={{
        paddingTop: "4px",
        maxWidth: `${265 * scale}px`,
        maxHeight: `${80 * scale}px`,
        ...props.style,
      }}
    />
  );
};

function LogOutButton(props: any) {
  const history = useHistory();
  // Show 'Log Out' button
  if (isSessionValid()) {
    return (
      <NavItem>
        <NavLink
          href="#"
          onClick={() => {
            clearSession();
            localStorage.removeItem('products');
            if (props.callback) props.callback();
            history.push("/");
          }}
        >
          <i className="fas fa-sign-out-alt" /> LOG OUT
        </NavLink>
      </NavItem>
    );
  }
  return <div />;
}




class HeaderNav extends React.Component {
  private isOpen: any = false;
  private showModal: boolean = false;

  public readonly toggleLoginModal = () => {
    this.showModal = !this.showModal;
    this.setState({});
  };

  public readonly toggle = () => {
    this.isOpen = !this.isOpen;
    this.setState({});
  };

  public render() {
    const isLoggedIn: boolean = isSessionValid();

    return (
      <Fragment>


        <Navbar expand="md" className=""  dark style={{ fontSize: "20px", color: "#BCBDBE" }}>
          {/* <Navbar dark expand="md" className="" style={{ width: "100%", fontSize: "18px" }}> */}
          {!isLoggedIn && <LoginModal modal={this.showModal} toggler={this.toggleLoginModal} />}

          <NavbarBrand href="/">
            <TSELogo style={{ paddingLeft: "0px" }} />
          </NavbarBrand>

          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <RRNavLink activeClassName="active" className="nav-link" exact to="/">
                  HOME
              </RRNavLink>
              </NavItem>

              <UncontrolledDropdown nav inNavbar >
                <DropdownToggle nav caret>
                  SOFTWARE
               </DropdownToggle>

                <DropdownMenu right>
                  {/* <RRNavLink to="/software/Precise808">
                    <DropdownItem>
                      Precise 808
                    </DropdownItem> 
                  </RRNavLink> */}

                  <RRNavLink to="/software/X50">
                    <DropdownItem>
                      TSE X50 v2
                  </DropdownItem>
                  </RRNavLink>

                  <RRNavLink to="/software/BOD">
                    <DropdownItem>
                      TSE BOD
                  </DropdownItem>
                  </RRNavLink>

                  <RRNavLink to="/software/R47">
                    <DropdownItem>
                      TSE R47
                  </DropdownItem>
                  </RRNavLink>

                  <DropdownItem divider />

                  <RRNavLink to="/software/LegacyProducts">
                    <DropdownItem>
                      Legacy Products
                  </DropdownItem>
                  </RRNavLink>

                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <RRNavLink activeClassName="active" className="nav-link" to="/contact">
                CONTACT
              </RRNavLink>
              </NavItem>

              <NavItem>
                <RRNavLink activeClassName="active" className="nav-link" to="/faq">
                FAQ
              </RRNavLink>
              </NavItem>

              <NavItem>
                {isLoggedIn ? (
                  <RRNavLink activeClassName="active" className="nav-link" to="/account">
                    <i className="fas fa-user" /> MY ACCOUNT
                  </RRNavLink>
                ) : (
                    <RRNavLink activeClassName="" className="nav-link" to="#" onClick={this.toggleLoginModal}>
                      <i className="fas fa-user" /> SIGN IN
                    </RRNavLink>
                  )}
              </NavItem>
            </Nav>

            <Nav className="ml-auto" navbar>
              <LogOutButton
                callback={() => {
                  this.setState({ ...this.state });
                }}
              />
            </Nav>
          </Collapse>
        </Navbar>
      </Fragment>
    );
  }
}


interface IStyledNav {
  isHome: boolean
}

const StyledNav = (options: IStyledNav) => {
  const size = useWindowSize();

  if (size.width && size.width > expandWidth) {
    return (
        <div style={{ paddingTop: "1em", position: "absolute", zIndex: 15 }}>
          <HeaderNav />
        </div>
    );
  }

  return (
    <HeaderNav />
  );
};


const clippedImages = [
  "/assets/images/carousel/clipped/r47_photo.jpg",
  "/assets/images/carousel/clipped/x30v1_photo.jpg",
  // "/assets/images/carousel/clipped/rack_photo.jpg",
  "/assets/images/carousel/clipped/pedalboard.jpg",
  "/assets/images/carousel/clipped/x50.jpg",
];

const getNavImage = (path?: string) => {
  if (path && path.includes("X50")) {
    return "/assets/images/carousel/clipped/x50.jpg";
  }

  if (path) {
    let h: number = 0;
    for (let i = 0; i < path.length; ++i)
      h = Math.imul(31, h) + path.charCodeAt(i) | 0;

    return clippedImages[Math.abs(h % clippedImages.length)];
  }

  return clippedImages[Math.floor(Math.random() * clippedImages.length)]
};



export default () => {
  const location = useLocation();
  const isHome = location.pathname === '/';

  return (
    <Fragment>
      <Row>
        <Col lg="12">
          <Row className="text-centering">
            <Col lg="1" />
            <Col lg="10" className="">
              <StyledNav isHome />
            </Col>
            <Col lg="1" />
          </Row>

          {
            // Only show carousel at home screen
            isHome &&
              <Row>
                <Col>
                  <X50Carousel />
                </Col>
              </Row>
          }

          {
            // Show image below navigation bar
            (!isHome) &&
              <Row>
                <Col style={{ minHeight: "80px" }}>
                  <img alt="navbar background" className="" src={getNavImage(location.pathname)} style={{ maxWidth: "100%", minHeight: "100px", maxHeight: "400px" }} />
                </Col>
              </Row>
          }

        </Col>
      </Row>

      <IRBrands />
    </Fragment>
  );
};


