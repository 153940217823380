import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faYoutube, faSoundcloud } from "@fortawesome/free-brands-svg-icons";

const Footer = (props: any) => {
  return (
    <Fragment>

      <Row className="dark-content text-center" style={{ paddingTop: "1em" }}>
        <Col>
          <span style={{ fontSize: "16px" }}><b>©</b> TSE AUDIO {new Date().getFullYear().toString()}</span>          
        </Col>
      </Row>


      <Row className="dark-content text-center" style={{ paddingTop: "1em" }}>
        <Col lg="12" style={{ color: "red" }}>
          <Row>
            <Col lg="4" />
            <Col><a href="https://twitter.com/tseaudio"><FontAwesomeIcon icon={faTwitter} size="1x"/> </a></Col>
            <Col><a href="https://facebook.com/tseaudio"><FontAwesomeIcon icon={faFacebook} size="1x"/> </a></Col>
            <Col><a href="https://soundcloud.com/tseaudio"><FontAwesomeIcon icon={faSoundcloud} size="1x"/> </a></Col>
            <Col><a href="https://youtube.com/tseaudio"><FontAwesomeIcon icon={faYoutube} size="1x"/></a></Col>
            <Col lg="4" />
          </Row>
        </Col>
      </Row>


      <Row className="dark-content text-center" style={{ paddingTop: "1em", height: "100px" }}>
        <Col lg="12" style={{ color: "#bfbfb"}}>
          <i style={{ fontSize: "12px" }}>
            <b>VST</b> is a trademark of Steinberg Media Technologies GmbH | <b>Audio Unit</b> is a trademark of Apple Computers Inc |{" "}
            <b>AAX</b> is a trademark of Avid Technology, Inc
          </i>
        </Col>
      </Row>

    </Fragment>
  );
};

export default Footer;
