import React from "react";
import { Button, Row, Col } from "reactstrap"
import PurchaseModal from "./PurchaseModal";

interface IPurchaseColumn {
  productTitle: string,
  price: string,
  purchaseHighlights?: string[],
  purchaseLink: string,
  demoVersionLink?: string,
  md?: string,
  imageObject?: any,
  downloadDemoLink?: string,
  
}

const PurchaseColumn = (options: IPurchaseColumn) => {
  const pm = PurchaseModal(options.purchaseLink);
  
  return (
    <Col md={options.md || "5"} style={{ padding: "1em" }}>
      { options.imageObject && <div>{options.imageObject}</div> }

      <h4 className="tse-orange">
        Purchase <span style={{ color: "#fff" }}>{options.productTitle}</span>
      </h4>
      
      {/* Selling points */}
      { options.purchaseHighlights &&
        <h6>
          <ul>
          { 
            options.purchaseHighlights.map((x, index) => { 
              return <li key={index}>{x}</li>;
            })
          }
          </ul>
        </h6>
      }

      {/* Buy now button */}
      <Row style={{ marginTop: "1em" }}>
        <Col>
          <Button className="btn-warning" style={{ width: "90%", color: "#000000" }} onClick={pm.toggle}>
            <i className="fas fa-shopping-cart" /> <b>BUY NOW</b>
            <br />
            <span style={{ color: "#ffffff" }}>
              <b>${options.price} USD</b>
            </span>
          </Button>

          {/* Add modal box */}
          {pm.component}

        </Col>

        {
          options.downloadDemoLink && 
            <Col>
              <Button className="btn-success" href="#get-demo" style={{ width: "90%", color: "#000000" }}>
                <i className="fas fa-download" /> <b>DOWNLOAD</b> <br />
                <span style={{ color: "#ffffff" }}>DEMO</span>
              </Button>
            </Col>
        }
      </Row>
    </Col>
  );
}

export default PurchaseColumn;