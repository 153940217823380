import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
    Row, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Button, Alert
} from "reactstrap";


const changePassword = async (email: string, newPassword: string, resetPwdToken: string) => {
    const changeStatus = {
        succeeded: true,
        errorMsg: ""
    };

    try {
        const result = await axios.post("https://store.tseaudio.com/api/users/change-password", { email, password: newPassword, resetPwdToken });
        console.log("result:", result);

        if (result.status !== 200) {
            changeStatus.succeeded = false;
            changeStatus.errorMsg = result.data.errorMsg || "unknown error";
        }

    } catch (error) {
        changeStatus.succeeded = false;
        changeStatus.errorMsg = "Internal server error. Please try requesting a new reset-password link!";
    }

    return changeStatus;
}

interface RouteParams {
    email: string,
    resetPwdToken: string
}


const ChangePasswordPage = (props: any) => {    
    const [ password1, setPassword1 ] = useState("");
    const [ password2, setPassword2 ] = useState("");
    
    const [ errorMsg, setErrorMsg ] = useState("");
    const [ completed, setCompleted ] = useState(false);
    
    const { email, resetPwdToken } = useParams<RouteParams>();

    if (!email || !resetPwdToken) {
        return (<div>Please request a new password-reset link.</div>)
    }

    if (completed) {
        return (
            <Row className="content text-center" style={{ padding: "2em", minHeight: "30em" }}>
                <Col lg="3"/>
                <Col lg="6">
                    Your password has been changed.<br/>
                    Please sign in using your new password.
                </Col>
                <Col lg="3"/>
            </Row>
        )
    }

    const attemptPasswordReset = async () => {
        if (password1 !== password2) {
            setErrorMsg("Passwords do not match.")
            return;
        }

        if (password1.length < 6) {
            setErrorMsg("Please use a password with at least 6 characters.");
            return;
        }

        setErrorMsg("");
        const status = await changePassword(email, password1, resetPwdToken);
        setCompleted(status.succeeded);

        if (! completed) {
            setErrorMsg(status.errorMsg || "Internal server error. Please try requesting a new reset-password link!");
        }
    };

    return (
        <Row className="content" style={{ paddingBottom: "2em", minHeight: "30em" }}>
        <Col>
            <Row style={{ paddingTop: "2em", paddingBottom: "1em" }}>
                <Col>
                    <h2 className="tse-orange">Change Password</h2>                    
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{ marginBottom: "2em" }}>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                attemptPasswordReset();
                            }}
                        >
                            <FormGroup>
                                Your new password:
                                <InputGroup style={{ width: "50%" }}>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-lock" />
                                        </InputGroupText>
                                    </InputGroupAddon>

                                    <Input 
                                        id="password1"
                                        name="password1"
                                        placeholder="your new super secure password"
                                        type="password"
                                        value={password1}
                                        onChange={ (event) => setPassword1(event.target.value) }
                                    />
                                </InputGroup>
                                <br/>
                                Confirm password:
                                <InputGroup style={{ width: "50%" }}>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-lock" />
                                        </InputGroupText>
                                    </InputGroupAddon>

                                    <Input 
                                        id="password2"
                                        name="password2"
                                        placeholder="your new super secure password, repeated"
                                        type="password"
                                        value={password2}
                                        onChange={ (event) => setPassword2(event.target.value) }
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Form>

                        {
                            (errorMsg.length > 0) && (
                                <Alert color="danger" style={{width:"50%"}}>
                                    {errorMsg}
                                </Alert>
                            )
                        }

                        <Button
                            className="btn-warning"
                            style={{ width: "50%" }}
                            disabled={ completed }
                            onClick={attemptPasswordReset}
                        >
                            <i className="fas fa-unlock" /> Update Password
                        </Button>
                    </div>
                </Col>
            </Row>
        </Col>
        </Row>
    );
}


export default ChangePasswordPage;