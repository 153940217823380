import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import { GeneratedSystemRequirements } from "./SystemRequirements";
import { IProductRequirements } from "./Product";
import DonationForm from "./Donate";
import ProductJumbotron from "./ProductJumbotron";
import DownloadButton from "../buttons/downloadButton";

const minimumRequirements: IProductRequirements = {
  windows: {
    cpu: "Intel Core 2 or newer",
    operatingSystem: "Windows 7",
    daw: "32/64 bit compatible",
    pluginFormats: ["VST2"],
  },
  macOS: {
    cpu: "Intel Core 2 or newer",
    operatingSystem: "OS X 10.7",
    daw: "64 bit compatible",
    pluginFormats: ["VST2", "AudioUnit"],
  },
};


const R47 = () => {
  return (
    <Fragment>
      <Row className="content">
        <Col lg="12">
          <ProductJumbotron
            freeware={true}
            bottomBordered={false}
            productTitle="TSE R47"
            productSubtitle="Guitar Distortion Plugin"
            productVersion="1.1"
            productImageUrl="/assets/images/products/R47.jpg"
            productDescription={
              <div>
                The <b className="tse-orange">R47</b> is based on the famous RAT&trade; distortion pedal.
            <br />
            Read the included manual for more info.
            {/* This plugin includes controls for overdrive, presence to add upper harmonic contents and definition, tone controls, and
                        a blend control to mix clean signal with the processed signal.
                        <br/><br/>
                        When you need a good bass tone quick, slap this on your track and you're already half-way there! */}
              </div>
            }
          />
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          {/* Product resources  */}
          <Row className="content" style={{ paddingTop: "1em", paddingBottom: "1em" }}>

            <Col lg="4" style={{ margin: "10px" }}>
              <Row>
                <Col>
                  <h5 className="tse-orange">
                    <i className="fas fa-download" /> Download TSE R47
              </h5>
                </Col>
              </Row>

              <Row style={{ marginTop: "1em" }}>
                <Col className="text-center">
                  <DownloadButton
                    downloadType="windows"
                    titleFirst="Windows x86"
                    titleEnd="Plugin"
                    to="/files/TSE_R47_v1.1_WIN32.msi"
                  />
                  <DownloadButton
                    downloadType="windows"
                    titleFirst="Windows x64"
                    titleEnd="Plugin"
                    to="/files/TSE_R47_v1.1_WIN64.msi"
                  />
                  <DownloadButton
                    downloadType="apple"
                    titleFirst="macOS"
                    titleEnd="Plugin"
                    to="/files/TSE_R47_v1.1_OSX.zip"
                  />
                </Col>
              </Row>
            </Col>

            <Col style={{ margin: "10px" }}>
              <h5 className="tse-orange">
                <i className="fas fa-cog" /> System Requirements
          </h5>
              <GeneratedSystemRequirements Requirements={minimumRequirements} showTitle={false} />
            </Col>

            <Col lg="4" style={{ margin: "10px" }}>
              <h5 className="tse-orange">
                <i className="fas fa-donate" /> Support Development
          </h5>
              {DonationForm()}
            </Col>

          </Row>

        </Col></Row>
    </Fragment>
  );
};

export default R47;
