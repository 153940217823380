import React, { useState, Fragment } from "react";
import {
  UncontrolledCarousel,
  Alert,
  Col,
  Row,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter,
} from "reactstrap";
import YouTube from "react-youtube";
import Iframe from "react-iframe";
import { GeneratedSystemRequirements } from "./SystemRequirements";
import { IProductRequirements } from "./Product";
import ProductJumbotron from "./ProductJumbotron";
import DownloadButton from "../buttons/downloadButton";
import PurchaseColumn from "./PurchaseColumn"

const demoLinks = {
  windows: "/files/TSE_X50_v2.4.8_DEMO_WIN.zip",
  mac: "/files/TSE_X50_v2.4.8_Demo.dmg"
};

const X50 = () => {
  return (
    <Fragment>
      <Row className="content" style={{ /*paddingBottom: "2em", paddingLeft: "1em", paddingRight: "1em"*/ }}>
        <Col>
          <ProductJumbotron
            productTitle="TSE X50 v2"
            productSubtitle="Rock And Metal Suite" // - VST2/AU/AAX"
            bottomBordered
            productVersion="2.4.8"
            imageObject={
              <Row className="content " style={{ paddingTop: "1em" }}>
                <Col>
                  <Row>
                    <Col>
                      <UncontrolledCarousel className="shadow" items={x50CarouselItems} />
                    </Col>
                  </Row>

                  <Row className="content" style={{ marginTop: "2em" }}>
                    <Col lg="12" id="get-demo">
                      <Row>
                        <Col className="text-center" style={{ padding: "1em" }}>
                          <h5 >Download <span className="tse-orange">TSE X50 v2</span> demo version!</h5>
                        </Col>
                      </Row>

                      <Row className="" style={{ padding: "1em", paddingTop: "0em" }}>
                        <Col >
                          <DownloadButton titleFirst="Windows" titleEnd="Installer" to={demoLinks.windows} downloadType="windows" />
                        </Col>

                        <Col>
                          <DownloadButton titleFirst="macOS" titleEnd="Installer" to={demoLinks.mac} downloadType="apple" />
                        </Col>
                      </Row>

                      <Row style={{ padding: "1em", paddingTop: "0em" }}>
                        <Col lg="12">
                          <Row>
                            <Col></Col>

                            <Col>
                              <p><i>Demo version limitations</i><br/>
                              <RedMinus /> Audio cut-off (periodic silence)<br />
                              <RedMinus /> Saving of plugin state is disabled
                              </p>
                            </Col>                                                      
                          </Row>
                        </Col>                        
                      </Row>

                    </Col>
                  </Row>
                </Col>
              </Row>
             
            }
            productDescription={
              <div style={{ fontSize: "20px" }}>
                The <span className="tse-orange">TSE X50 v2</span> is a guitar amplifier simulation suite crafted around a digital emulation of a 
                famous high-gain US guitar amp.<br /><br />
                History gave us two almost identical amps in the series and this lead to both red channel circuits being modeled, where
                the original revision had a red channel with low-end punch and the later revision got a tightened up low-end. The green
                channel and power amp is modeled after the first [MK I] revision of the amp.
                <br />
                <br />
                <span style={{ color: "#fff"}}>High quality Impulse responses</span> (IRs) is of outmost importance when dialing in your guitar tone. Because of this we have hand-picked the best sounding IRs to provide ten individual IR packs from some of the
                greatest IR creators on the market that we believe are perfect for our software.
                
                <br />
                <br />
                After its initial release, <span className="tse-orange">TSE X50 v2</span> has received a pedal board section that houses a handful of pedal emulations, and a FX
                rack with the most basic set of effects needed to create the perfect tone. TSE X50 v2 also comes with our 5 year anniversary
                model of the <span className="tse-orange">TSE X30</span> preamp.
                <br />
                <br />
                <i>You simply cannot go wrong with this amp for rock or metal!</i>

              </div>
            }
          />
        </Col>
      </Row>
     

     
      {/*==============================================================================================*/}
      {/* Purchase Product */}
      {/* <PurchaseSection /> */}

      <Row className="content" style={{ paddingTop: "2em", paddingBottom: "2em"}}>

        <PurchaseColumn
                productTitle="TSE X50 v2"
                price="69.99"
                purchaseHighlights={[
                  "Single-user license",
                  "Simultaneous installations on up to 3 of your personal computers",
                  "Non-intrusive copy protection scheme by use of license file",
                  "Available in VST2, AU and AAX64 formats"
                ]}
                purchaseLink="https://sites.fastspring.com/tseaudio/instant/X50v2SL"
                md="5"
                imageObject={
                  <img
                    src="/assets/images/products/X50box.png"
                    alt="X50 Product box"
                    width="50%"
                    style={{ paddingTop: "10px", minWidth: "50px", maxWidth: "200px" }}
                  />
                }
              />

        <Col lg="1" />

        <Col style={{ borderLeft: "0px solid #444444ab", padding: "1em" }}>
          <h5 className="tse-orange">TSE X50 v2 Demos</h5>
          <Iframe
            url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/21701226&amp;auto_play=false&amp;hide_related=false&amp;visual=false"
            id="soundcloud"
            frameBorder={0}
            width="100%"
            height="90%"
          />
        </Col>
      </Row>

      {/*==============================================================================================*/}
      {/* PRODUCT OVERVIEW */}
      <Row className="content-highlight" style={{ marginTop: "1em", paddingTop: "2em", paddingBottom: "2em"}}>
        <Col>
          <h4 className="tse-orange">TSE X50 Overview</h4>
          <ProductComponents />
        </Col>
      </Row>

      {/*==============================================================================================*/}
      {/* System Requirements */}
      <Row className="content" style={{ paddingTop: "2em", marginTop: "1em", marginBottom: "1em" }}>
        <Col>
          <h4 className="tse-orange">Minimum System Requirements</h4>
          <GeneratedSystemRequirements Requirements={minimumRequirements} showTitle={false} />
        </Col>
      </Row>


      {/*==============================================================================================*/}
      {/* MEDIA */}
      <Row className="content-highlight" style={{ paddingTop: "2em", paddingBottom: "2em" }}>
        
        <Col className="">
          <Row>
            <Col>
              <h4 className="tse-orange">Media</h4>
            </Col>
          </Row>

          <Row>

          <Col lg="6" className="text-center" style={{ padding: "10px" }}>
                  <Card>
                    <YouTube videoId="XjxtBrId4Q4" opts={{ width: "100%" }} />
                    <CardFooter>Property of <a href="https://www.youtube.com/channel/UCAtlZO9a52JIhQRyXDRLaZQ">Sumerian Records</a></CardFooter>
                  </Card>
                </Col>

                <Col lg="6" className="text-center" style={{ padding: "10px" }}>
                  <Card>
                    <YouTube videoId="g_XgGbOvaH0" opts={{ width: "100%" }} />
                    <CardFooter>Property of <a href="https://www.youtube.com/channel/UCKsHNVAOadrboThsH5qXKTg">Humanity's Last Breath</a></CardFooter>
                  </Card>
                </Col>
                
                            

            <Col lg="6" className="text-center" style={{ padding: "10px" }}>
                <Card>
                  {/* <YouTube videoId="X1eKX7KdrM0" opts={{width: "100%"}}/> */}
                  <YouTube videoId="0HTmx7knSuQ" opts={{ width: "100%" }} />
                  <CardFooter>Property of <a href="https://www.youtube.com/c/JFTercero/featured">JFT Producciones</a></CardFooter>
                </Card>
              </Col>

              <Col lg="6" className="text-center" style={{ padding: "10px" }}>
                <Card>
                  <YouTube videoId="X1eKX7KdrM0" opts={{ width: "100%" }} />
                  <CardFooter>Property of <a href="https://www.youtube.com/c/mehstudio/featured">mehstudio</a></CardFooter>
                </Card>
              </Col>

            </Row>

            <Row>
              <Col lg="6" className="text-center" style={{ padding: "10px" }}>
                <Card>
                  <YouTube videoId="hK3H_WnaYbk" opts={{ width: "100%" }} />
                  <CardFooter>Property of <a href="https://www.youtube.com/c/mehstudio/featured">mehstudio</a></CardFooter>
                </Card>
              </Col>
              
              <Col lg="6" className="text-center" style={{ padding: "10px" }}>
                <Card>
                  <YouTube videoId="bYyO13zGHGI" opts={{ width: "100%" }} />
                  <CardFooter>Property of <a href="https://www.youtube.com/c/mehstudio/featured">mehstudio</a></CardFooter>
                </Card>
              </Col>

            </Row>


        </Col>

      
      </Row>


    </Fragment>
  );
};

export default X50;

const PurchaseSection = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <Row className="content-highlight" style={{ paddingTop: "2em", paddingBottom: "2em", marginTop: "1em", marginBottom: "1em" }}>
      {/* <Col lg="1" /> */}

      <Col md="4" style={{ padding: "10px" }}>
        <h4 className="tse-orange">PURCHASE TSE X50 v2.4
          <br />
          <span style={{ color: "#fff" }}>$69.99</span> USD
        </h4>
        <h6>- Single-user license.</h6>
        <h6>- Simultaneous installations on up to 3 of your personal computers.</h6>
        <h6>- Non-intrusive copy protection scheme by use of license file.</h6>

        <img
          src="/assets/images/products/X50box.png"
          alt="X50 Product box"
          width="100%"
          style={{ paddingTop: "10px", minWidth: "50px", maxWidth: "300px" }}
        />
        <Row style={{ paddingTop: "1em" }}>
          <Col>
            <Button className="btn-warning" style={{ width: "90%", color: "#000000" }} onClick={toggle}>
              <i className="fas fa-shopping-cart" /> PURCHASE <br />
              <span style={{ color: "#ffffff" }}>
                <b>$69.99</b>
              </span>
            </Button>

            <div>
              <Modal isOpen={modal} toggle={toggle} className="">
                <ModalHeader toggle={toggle} className="tse-orange"><i className="fas fa-info-circle"></i> Purchase Information.</ModalHeader>
                <ModalBody>
                
                <ul>
                  <li>
                  <p>
                    We highly recommend our customers to test the TSE X50 v2 <span className="tse-orange">demo version</span> on your system before purchasing the product, 
                    to ensure <b>compatibility</b> and that you know what you are buying before completing the purchase.
                  </p>

                  </li>
                  <li>
                  <p>
                    After completing your purchase you will receive a <span className="tse-orange">license file</span> from FastSpring and an <span className="tse-orange">account</span> is created for you here at tseaudio.com.
                  </p>
                  </li>
                  <li>
                    Instructions on setting your <span className="tse-orange">account password</span> will be sent to your e-mail address.                    

                  </li>
                </ul>


                  <p>
                    Thank you for purchasing TSE X50 v2! <br />- TSE Audio
                  </p>
                </ModalBody>
                <ModalFooter>
                  <a href="https://sites.fastspring.com/tseaudio/instant/X50v2SL">
                  <Button className="btn-warning" style={{ color: "#000000" }}>
                    <i className="fas fa-check-circle" /> CONTINUE TO FASTSPRING
                  </Button>
                  </a>

                  <Button color="danger" onClick={toggle}>CANCEL</Button>
                </ModalFooter>
              </Modal>
            </div>
          </Col>

          <Col>
            <Button className="btn-success" href="/software/X50#get-demo" style={{ width: "90%", color: "#000000" }}>
              <i className="fas fa-download" /> DOWNLOAD <br />
              <span style={{ color: "#ffffff" }}>DEMO</span>
            </Button>
          </Col>
        </Row>
      </Col>

      <Col lg="2" className="align-self-center" style={{ padding: "10px", paddingTop: "1em", paddingBottom: "1em" }}>

          <div className="box">
            <span style={{ fontSize: "20px" }}>
              <span style={{ color: "#7DB900" }}>Full</span> version</span>
            <br />
            <i>All components included</i>
            <br />
            <br />
            <GreenPlus /> Pedal Board <br />
            <GreenPlus /> X50 Guitar Amp <br />
            <GreenPlus /> Impulse Loader <br />
            <GreenPlus /> 10 IR Packs <br />
            <GreenPlus /> FX Rack <br />
            <GreenPlus /> Preset Manager <br />
            </div>

      </Col>

      <Col style={{ borderLeft: "0px solid #444444ab", padding: "1em" }}>
        <h5 className="tse-orange">TSE X50 v2 Demos</h5>
        <Iframe
          url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/21701226&amp;auto_play=false&amp;hide_related=false&amp;visual=false"
          id="soundcloud"
          frameBorder={0}
          width="100%"
          height="90%"
        />
      </Col>
    </Row>

  );
};







const GreenPlus = () => {
  return <i className="fas fa-plus-circle" style={{ color: "#7DB900", fontSize: "14px" }} />;
};

const RedMinus = () => {
  return <i className="fas fa-minus-circle" style={{ color: "#D71F00", fontSize: "14px" }} />;
};

const fireIcon = <i className="fas fa-fire" style={{ color: "#ce9100", fontSize: "14px" }} />;

const plusIcon = <GreenPlus />;

const minimumRequirements: IProductRequirements = {
  windows: {
    cpu: "Intel Core 2 or later",
    operatingSystem: "Windows 7",
    daw: "Pro Tools 11 or VST compatible",
    pluginFormats: ["VST2", "AAX"],
  },
  macOS: {
    cpu: "Intel Core 2 or later",
    operatingSystem: "OS X 10.9",
    daw: "Logic Pro X, Pro Tools 11 or VST compatible",
    pluginFormats: ["VST2", "AudioUnit", "AAX"],
  },
};

const x50CarouselItems = [
  {
    key: "1",
    caption: "Pedal Board",
    altText: "Pedal Board",
    header: " ",
    src: "/assets/images/products/X50/3.png",
  },
  {
    key: "3",
    caption: "TSE X50 v2",
    altText: "TSE X50 v2",
    header: " ",
    src: "/assets/images/products/X50/1.png",
  },
  {
    key: "2",
    caption: "Impulse Loader",
    altText: "Impulse Loader",
    header: " ",
    src: "/assets/images/products/X50/4.png",
  },
  {
    key: "4",
    caption: "FX Rack",
    altText: "FX Rack",
    header: " ",
    src: "/assets/images/products/X50/5.png",
  },
];



const ProductComponents = () => {
  return (
    <Row style={{ paddingTop: "1em" }}>
      <Col lg="6">
        <ul className="" style={{ fontSize: "16px", listStyleType: "none", lineHeight: "24px", textAlign: "left" }}>
          <li>
            {plusIcon} <strong>Zero-latency</strong> and <strong>topology preserving</strong> modeling throughout the entire signal chain.
          </li>

          <br />
          <li>
            {plusIcon} <strong>Input Learn</strong>
            <ul>- Adjusts your guitar input signal to its optimal setting automatically.</ul>
          </li>

          <br />
          <li>
            {plusIcon} <strong>Oversampling</strong>
            <ul>- Oversampling from 2x to 8x to reduce unwanted aliasing.</ul>
            <ul>- Automatic oversampling from 2X to 4X / 4X to 8X during offline rendering.</ul>
          </li>

          <br />
          <li>
            {plusIcon} <strong>Preset Manager</strong>
            <ul>- A flexible module based preset manager to organize your favorite tones.</ul>
          </li>

          <br />
          <li>
            {plusIcon} <strong>Pedal Board</strong>
            <ul style={{ listStyleType: "none" }}>
              <li>{fireIcon} NoiseGate</li>
              <li>{fireIcon} TSE808</li>
              <li>{fireIcon} R47</li>
              <li>{fireIcon} Phaser</li>
              <li>{fireIcon} Pandora's Box</li>
              <li>{fireIcon} SDM-2</li>
            </ul>
          </li>

          <br />
          <li>
            {plusIcon} <strong>Guitar Amplifiers</strong>
            <ul>
              {fireIcon} <strong>TSE X50 v2.4 Pre Amp and Power Amp</strong>
              <br />
              {fireIcon} <strong>TSE X30 v2.4 Pre Amp</strong>
              <ul>- Guitar amplifier simulations completely re-modeled with interconnecting algorithms!</ul>
            </ul>
            <ul>
              {fireIcon} <strong>Power Tubes</strong>
              <ul>- Switch between using 6L6GC or EL34 pentode models.</ul>
            </ul>
            <ul>
              {fireIcon} <strong>Bias</strong>
              <ul>- Use the bias control to set the operating-point that suits you most. (Power Amp)</ul>
            </ul>
          </li>
        </ul>
      </Col>

      <Col lg="6">
        <ul className="" style={{ fontSize: "16px", listStyleType: "none", lineHeight: "24px", textAlign: "left" }}>
          <li>
            {plusIcon} <strong>FX RACK</strong> - Essential tools for getting that quick, good tone.
            <ul style={{ listStyleType: "none" /*, marginLeft: "-24px"*/ }}>
              <li>
                {fireIcon} <strong>Tuner</strong> - <i>High precision</i> tuner for low-tuned guitars.
              </li>
              <li>
                {fireIcon} <strong>Delays</strong>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <strong>King-Pong</strong> - Stereo pong delay (optional asymmetry).
                  </li>
                  <li>
                    <strong>V-Lay</strong> - Stereo Delay / Vibrato effect.
                  </li>
                </ul>
              </li>
              <li>
                {fireIcon} <strong>Reverb</strong> - Hall effect with position switching.
              </li>
              <li>
                {fireIcon} <strong>Equalizer</strong> - HP/LP, Lowshelf/Highshelf and peakfilter.
              </li>
            </ul>
          </li>

          <br />
          <li>
            {plusIcon} <strong>FIRE! Impulse Loader</strong>
            <ul>
              {fireIcon} <strong>Zero-latency IR-loader</strong> with adjustable IR length.
            </ul>
            <ul>
              {fireIcon} <strong>IR Analyzer</strong> - Compare IR length vs frequency response in a graphical way!
            </ul>
            <ul>
              {fireIcon} <strong>User IR Directory</strong> - Point X50 to your own favorite IR collection.
            </ul>
            <ul>
              {fireIcon} <strong>Movement Control</strong> - Poweramp to speaker emulation including your favorite IR into it's process.
            </ul>
          </li>

          <br />
          <li>
            {plusIcon} <strong>Bundled IR packs</strong> that is carefully hand-picked and generously provided by <br />
            <ul style={{ listStyleType: "none" /*, marginLeft: "-24px"*/ }}>
              <li>{fireIcon} Chop Tones</li>
              <li>{fireIcon} 3 Sigma Audio</li>
              <li>{fireIcon} Rosen Digital</li>
              <li>{fireIcon} Fat Lazy Cabs</li>
              <li>{fireIcon} Kalthallen Cabs</li>
              <li>{fireIcon} Frozen Sun Audio</li>
              <li>{fireIcon} Trackmix Studio</li>
              <li>{fireIcon} GuitarHack</li>
              <li>{fireIcon} Ryan Harvey Audio</li>
              <li>{fireIcon} SinMix</li>
              <li>{fireIcon} Panda Sound</li>
            </ul>
          </li>
        </ul>
      </Col>
    </Row>
  );
};
