import axios from "axios";
import React, { useState } from "react";
import { setSession, isSessionValid } from "../../session";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Spinner
} from "reactstrap";
import debug from "debug";
import { useHistory } from "react-router-dom";
// import { ReactComponent as Logo } from "../../assets/logo.svg";

// const TSELogo = (props: any) => {
//     const scale: number = 0.5;
//     return (
//       <Logo fill="#BCBDBE" style={{
//         maxWidth: `${265 * scale}px`,
//         maxHeight: `${80 * scale}px`,
//         ...props.style,
//       }} />);
//   }


interface ILoginProps {
    email: string,
    password: string
}


const handleLogin = async (props: ILoginProps) => {
    const { email, password } = props;

    try {
        const response =
            await axios.post<{
                token: string;
                expiry: string;
                email: string;
                firstName: string;
                lastName: string;
            }>
                ("https://store.tseaudio.com/api/users/login", {
                    email, password
                });

        debug.log(response);

        setSession(
            response.data.token,
            response.data.expiry,
            response.data.email,
            response.data.firstName,
            response.data.lastName
        );

        return true;

        // this.setState({ isLoggedIn: true });
    } catch (error) {
        // this.setState({ error: "Something went wrong" });
        debug.log("error:", error);
    }

    return false;
};



function LoginModal(props: any) {
    const modal = props.modal;
    const toggle = props.toggler;

    const [pwd, updatePwd] = useState("");
    const [email, updateEmail] = useState("");
    const [badUserPwd, informUser] = useState(false);
    const [buttonDisable, toggleButtonDisable] = useState(false);
    const history = useHistory();

    if (isSessionValid()) {
        return (<div/>);
    }

    const doLogin = async () => {
        toggleButtonDisable(true);
        informUser(false);
        const loggedIn = await handleLogin({ email, password: pwd }).catch(onrejected => console.log("oops!"));

        if (loggedIn) {
            toggle();
            history.push("/account");
        } else {
            informUser(true);
            toggleButtonDisable(false);
        }

    };

    const closeBtn = <button className="close" onClick={toggle} style={{ color: "#ffffff" }}>&times;</button>;

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} className="login-modal">
                <ModalHeader toggle={toggle} close={closeBtn} style={{ borderBottom: "0px", justifyContent: "center" }}>
                    {/* <TSELogo/> */}
                    <i className="fas fa-user tse-orange" /> <span className="tse-orange">Customer Login</span>
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label for="email">E-mail</Label>
                                <InputGroup style={{border: "0px"}}>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-user" />
                                        </InputGroupText>
                                    </InputGroupAddon>

                                    <Input
                                        name="email"
                                        id="email"
                                        placeholder="your@email.com"
                                        value={email}
                                        onChange={ (event: any) => updateEmail(event.target.value) }
                                    />
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <Label for="password">Password</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-key" />
                                        </InputGroupText>
                                    </InputGroupAddon>

                                    <Input
                                        id="password"
                                        name="password"
                                        type="password"
                                        placeholder="your secret"
                                        value={pwd}
                                        onChange={ (event: any) => updatePwd(event.target.value) }
                                        onKeyDown={
                                            (event: React.KeyboardEvent<HTMLInputElement>) => {
                                                if (event.key === "Enter" || event.keyCode === 13) {
                                                    doLogin();
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>


                <ModalFooter style={{ borderTop: "0px" }} className="login-modal-footer">
                    {/* <Row> */}

                        <Col className="text-center">
                            {
                                badUserPwd &&
                                <p>Bad Username or password</p>
                            }
                            <div>
                                <Button className="btn-warning"
                                        disabled={ buttonDisable }
                                        style={{width: "80%", color: "#000"}}
                                        onClick={ () => doLogin() }>
                                    <i className="fas fa-unlock" /*style={{color:"#fff"}}*/ /> Login
                                </Button>
                            </div>
                            <div>
                                { buttonDisable ? (
                                        <Spinner size="sm" color="secondary" style={{ marginLeft: "10px"}}/>
                                    ) : ""
                                }
                            </div>

                            <br/>
                            <div className="forgot">
                                <a href="/reset-password" style={{ fontSize: "small" }}>Forgot your password?</a>
                            </div>
                        </Col>
                    {/* </Row> */}

                </ModalFooter>
            </Modal>
        </div>
      );
};

export default LoginModal;