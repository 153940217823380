import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import History from 'history';

/*
    https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
    Usage:
        <Router>
        <Fragment>
            <ScrollToTop />
            <Switch>
                <Route path="/" exact component={Home} />
            </Switch>
        </Fragment>
        </Router>    
*/

const ScrollToTop: React.FunctionComponent<RouteComponentProps> = ({history}) => {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    }, []);
  
    return (null);
}

export default withRouter(ScrollToTop);
