import React from "react";
import { Row, Col } from "reactstrap";


const IRBrands = () => {
    const imgStyle: React.CSSProperties = {
        maxWidth: "90%",
        height: "auto",
        opacity: "0.2",
    }

    return (
        <Row 
            className="dark-content" style={{ padding: "1em" }}>
            <Col xs="2">
                <img className="mx-auto d-block"  src="/assets/images/logos-inverted/kalthallen.png" alt="Kalthallen Cabs" style={{...imgStyle, paddingTop: "8px"}} />
            </Col>

            <Col xs="2">
                <img className="mx-auto d-block" src="/assets/images/logos-inverted/fatlazy.png" alt="Fat Lazy Cabs" style={{...imgStyle, paddingTop: "8px"}} />
            </Col>

            <Col xs="2">
                <img className="mx-auto d-block" src="/assets/images/logos-inverted/gh_logo.png" alt="Guitar Hack" style={{...imgStyle, paddingTop: "5px"}} />
            </Col>

            <Col xs="2">
                <img className="mx-auto d-block" src="/assets/images/logos-inverted/frozensun.png" alt="Frozen Sun Audio" style={{...imgStyle, paddingTop: "4px"}} />
            </Col>

            <Col xs="2">
                <img className="mx-auto d-block" src="/assets/images/logos-inverted/ryanharvey.png" alt="Ryan Harvey" style={{...imgStyle, paddingTop: "8px"}} />
            </Col>

            <Col xs="2">
                <img className="mx-auto d-block" src="/assets/images/logos-inverted/trackmixstudio.png" alt="Trackmix Studio" style={{...imgStyle, paddingTop: "8px"}} />
            </Col>
        </Row>
    )
}

export default IRBrands;