import React from "react";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import debug from "debug";
import { getToken } from "../../session";

interface IUserProps {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  groupLevelId: number;
  wantNews: boolean;
}

interface IAdminState {
  users: IUserProps[];
}

class Admin extends React.Component<{}, IAdminState> {
  constructor(props: any) {
    super(props);
    this.state = {
      users: [],
    };
  }

  public async componentDidMount() {
    console.log(this.state);
    const jwtPayload = getToken();
    const users = await axios.get<any>("/api/users/all", {
      headers: {
        Authorization: `Bearer ${jwtPayload.token}`,
      },
      data: {
        email: jwtPayload.email,
      },
    });

    debug.log(users.data);

    this.setState({ users: users.data });
  }

  public render() {
    return (
      <Container fluid>
        <Row>
          <Col>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>

                  <th>First Name</th>

                  <th className="hidden-xs-portrait">Last Name</th>

                  <th>Email</th>

                  <th className="hidden-xs-portrait" style={{ textAlign: "center" }}>
                    Groups
                  </th>

                  <th className="hidden-xs-portrait" style={{ textAlign: "center" }}>
                    Status
                  </th>

                  <th className="hidden-xs-portrait" style={{ textAlign: "center" }}>
                    Action
                  </th>

                  <th className="hidden-xs-portrait" style={{ textAlign: "center" }}>
                    Country
                  </th>

                  {/* <th className="hidden-xs-portrait" style={{ textAlign: "center" }}>Purchases</th> */}

                  {/* <th className="hidden-xs-portrait" style={{ textAlign: "center" }}>Spent</th> */}

                  {/* <th className="hidden-xs-portrait" style={{ textAlign: "center" }}>Beta</th> */}

                  <th className="hidden-xs-portrait" style={{ textAlign: "center" }}>
                    License
                  </th>
                </tr>
              </thead>

              <tbody>
                {/* <tr>
                                <td>userID</td>
                                <td>userFirstName</td>
                                <td>userLastName</td>
                                <td>userEmail</td>
                                <td style={{ textAlign: "center" }}>customer</td>
                                <td style={{ textAlign: "center" }}><Badge color="success">Active</Badge> </td>
                                <td style={{ textAlign: "center" }}>Edit</td>
                                <td style={{ textAlign: "center" }}>country</td>
                                <td style={{ textAlign: "center" }}>products</td>
                            </tr> */}

                {/* {
                                this.state.users.map((u: IUserProps) => {
                                    <tr>
                                        <td>u.id</td>
                                    </tr>
                                })
                            } */}
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Admin;
