import React, { Fragment, useState } from "react";
import {
  Col, Row, Card, CardImg, CardGroup,
  CardBody, CardTitle, CardText, Button, Jumbotron, CardFooter
} from "reactstrap";
import News from "./news";
import IRBrands from "./irbrands";
import YouTube from "react-youtube";
import { Link } from "react-router-dom";


interface IProductCard {
  name: string
  subtitle?: string
  text: string
  imageLink: string
}
const ProductCard = (options: IProductCard) => {
  const boxSize = "300px";
  let [isMouseOver, setMouseOver] = useState(false);

  const border = isMouseOver ? "4px" : "0px";
  const borderColor = "#CE9100";

  return (
    <Row className="shadow-sm" style={{ marginBottom: "1em" }}>
      <Col >
      <div className="" style={{ display: "flex" }}>
        {/* <div style={{ width: boxSize }}> */}
        <div className="text-center" onMouseOver={e => setMouseOver(true)} onMouseLeave={e => setMouseOver(false)} style={{ minWidth: "500px", border, borderColor }}>
            <img className="rounded" src={options.imageLink} height={boxSize} style={{ }}/>
            {/* { isMouseOver && <p>foo</p>} */}
        </div>

        <div className="content-highlight" style={{ flexGrow: 1, paddingLeft: "2em", paddingTop: "2em" }} >
          <h3 className="tse-orange">{options.name}</h3>
          
            {options.subtitle && <h5>{options.subtitle}</h5>}

            {options.text}
        </div>
        </div>
      </Col>
    </Row>
  );
}



const Home = () => {
  return (
    <Fragment>
      <Row className="content" style={{ paddingTop: "1em", paddingBottom: "1em"}}>
        <Col>
          <Row>
            <Col className="tse-orange">
              <h3>Introduction</h3>
            </Col>
          </Row>
          <Row>
            <Col>

            <p className="lead">
                TSE Audio is a developer of audio plugins that extends the
                Digital Audio Workstation with tools for musicians and mixing
                engineers working on guitar and bass guitar tracks productions.
                At the core of our products are real-time simulations of the electrical circuitry of analog
                hardware units such as guitar amplifiers and pedals, carefully designed to not overload your system capabilities while providing
                the best sound quality.
              </p>            
            </Col>
          </Row>

          <Row className="content" style={{ paddingTop: "2em", paddingBottom: "2em" }}>

            <Col className="">
              <Row>
                <Col>
                  <h3 className="tse-orange">Inspiration</h3>
                  <p className="lead">
                    Our products can be heard on professional productions like <a href="https://www.youtube.com/watch?v=qmHOIDUApfA">Twilight Force</a>, or <a href="https://impact-studios.com/">Impact Studios</a> that has used the <Link to="/software/X50">TSE X50 v2</Link> in their work for artists
                    like <a href="https://www.facebook.com/bornofosiris">Born of Osiris</a> and <a href="https://humanitys-last-breath.com/">Humanity's Last Breath. </a>
                    Impact Studios has also released their own X50 presets and cab impulses by Buster Odeholm that has been used in their productions and can be found <a href="https://impact-studios.com/products/tse-ir-pack-1">here</a>.
                    <br /><br />
                    <Link to="/software/X50">TSE X50 v2</Link> is our flagship guitar amp modeler loaded with high-quality third-party impulse responses and the most important pedals and rack effects to
                    nail that quick and good tone for demos or productions. We have a free demo version for evaluation!
                  </p>
                </Col>
              </Row>

              <Row>

                <Col lg="6" className="text-center" style={{ padding: "10px" }}>
                  <Card>
                    <YouTube videoId="XjxtBrId4Q4" opts={{ width: "100%" }} />
                    <CardFooter>Property of <a href="https://www.youtube.com/channel/UCAtlZO9a52JIhQRyXDRLaZQ">Sumerian Records</a></CardFooter>
                  </Card>
                </Col>

                <Col lg="6" className="text-center" style={{ padding: "10px" }}>
                  <Card>
                    <YouTube videoId="g_XgGbOvaH0" opts={{ width: "100%" }} />
                    <CardFooter>Property of <a href="https://www.youtube.com/channel/UCKsHNVAOadrboThsH5qXKTg">Humanity's Last Breath</a></CardFooter>
                  </Card>
                </Col>

                <Col lg="6" className="text-center" style={{ padding: "10px" }}>
                  <Card>
                    <YouTube videoId="0HTmx7knSuQ" opts={{ width: "100%" }} />
                    <CardFooter>Property of <a href="https://www.youtube.com/c/JFTercero/featured">JFT Producciones</a></CardFooter>
                  </Card>
                </Col>

                <Col lg="6" className="text-center" style={{ padding: "10px" }}>
                  <Card>
                    <YouTube videoId="X1eKX7KdrM0" opts={{ width: "100%" }} />
                    <CardFooter>Property of <a href="https://www.youtube.com/c/mehstudio/featured">mehstudio</a></CardFooter>
                  </Card>
                </Col>

              </Row>

              <Row>
                <Col lg="6" className="text-center" style={{ padding: "10px" }}>
                  <Card>
                    <YouTube videoId="hK3H_WnaYbk" opts={{ width: "100%" }} />
                    <CardFooter>Property of <a href="https://www.youtube.com/c/mehstudio/featured">mehstudio</a></CardFooter>
                  </Card>
                </Col>

                <Col lg="6" className="text-center" style={{ padding: "10px" }}>
                  <Card>
                    <YouTube videoId="bYyO13zGHGI" opts={{ width: "100%" }} />
                    <CardFooter>Property of <a href="https://www.youtube.com/c/mehstudio/featured">mehstudio</a></CardFooter>
                  </Card>
                </Col>

              </Row>


            </Col>


          </Row>

        </Col>
        <Col lg="3">
          <News />
        </Col>
      </Row>


     {/* <Row className="content">
       <Col lg="2" />
      <Col lg="8" className="">

        <ProductCard name="TSE X50" subtitle="bam" text="all bam boo"/>
        <ProductCard name="BOD" subtitle="whapp!" text="all bam boo"/>
        <ProductCard name="R47" subtitle="LOL" text="all bam boo"/>


      </Col>

       <Col lg="3">
       </Col>
     </Row> */}




    </Fragment>
  );
};

export default Home;


const foo = () => {
  return (
    <Fragment>
    <Row className="content">
    <Col className="" lg="8">
      <Jumbotron className="">
        <h3 className="tse-orange">Audio Software</h3>

        {/* <p className="lead">
          TSE Audio is a developer of audio plugins that extends the
          Digital Audio Workstation with tools for musicians and mixing
          engineers working on guitar and bass guitar tracks.
            <br />
          <br />
            At the core of our products are real-time simulations of the electrical circuitry of analog
            hardware units such as guitar amplifiers and pedals, carefully designed to not overload your system resources while providing
            the best sound quality.
          </p> */}


        <CardGroup>

          <Card>
            <CardBody>
              <CardTitle className="tse-orange">TSE BOD</CardTitle>
              <CardImg top src="assets/images/products/BOD.png" alt="Product" className="shadow" />
              {/* <CardSubtitle>Bass Guitar Plugin</CardSubtitle> */}
              <CardText>Bass overdrive pedal emulation, based on a popular hardware unit.</CardText>
            </CardBody>
            <Button className="btn-warning" style={{ color: "black" }}>Learn More</Button>
          </Card>

          <Card>
            <CardBody>
              <CardTitle className="tse-orange">TSE X50 v2.4</CardTitle>
              <CardImg top src="assets/images/products/X50.png" alt="Product" />
              {/* <CardSubtitle>Bass guitar processor</CardSubtitle> */}
              <CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>
            </CardBody>
            <Button className="btn-warning" style={{ color: "black" }}>Learn More</Button>
          </Card>

          <Card>
            <CardBody>
              <CardTitle className="tse-orange">TSE R47</CardTitle>
              <CardImg top src="assets/images/products/R47.jpg" alt="Product" className="shadow-lg" />
              {/* <CardSubtitle>Distortion Pedal</CardSubtitle> */}
              <CardText>Guitar distortion pedal emulation based on the famous RAT&trade; pedal.</CardText>
            </CardBody>
            <Button className="btn-warning" style={{ color: "black" }}>Learn More</Button>
          </Card>
        </CardGroup>
      </Jumbotron>
    </Col>


    <Col className="" lg="1">
    </Col>

    <Col className="" lg="3">

    </Col>
  </Row>

  <Row>
    <Col>
      <Jumbotron className="dark-content">
        <h3 className="tse-orange">About us</h3>

        <p className="lead">
          TSE Audio is a developer of audio plugins that extends the
          Digital Audio Workstation with tools for musicians and mixing
          engineers working on guitar and bass guitar tracks.
            <br />
          <br />
            At the core of our products are real-time simulations of the electrical circuitry of analog
            hardware units such as guitar amplifiers and pedals, carefully designed to not overload your system resources while providing
            the best sound quality.
          </p>
      </Jumbotron>
    </Col>
  </Row>
  </Fragment>
  );
}