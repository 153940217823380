import React from "react";
import { useParams } from "react-router-dom";
import X50 from "./products/X50";
import BOD from "./products/BOD";
import R47 from "./products/R47";
// import Precise808 from "./products/Precise808";
import LegacyProducts from "./products/Legacy";

// class Software extends React.Component {
//     public render() {

//         const { product } = useParams();

//         return (
//             <h1>Software</h1>
//             <div>
//                 foo
//                 {/* { product } */}
//             </div>
//         );
//     }
// }

const Software = () => {
  const { product } = useParams<{product: string}>();

  if (product === "X50" || product === "x50v2" || product === "X50v2") {
    return <X50 />;
  }

  if (product === "BOD") {
    return <BOD />;
  }

  if (product === "R47") {
    return <R47 />;
  }

  // if (product === "Precise808")
  //   return <Precise808 />;

  if (product === "LegacyProducts")
    return <LegacyProducts />

  return <X50 />;
};

export default Software;
