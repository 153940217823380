import axios from "axios";
import React, { useState } from "react";
import {
    Row, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Button, Alert
} from "reactstrap";
import * as EmailValidator from "email-validator";

const sendResetPasswordLink = async (email: string) => {
    const resetStatus = {
        succeeded: true,
        errorMsg: ""
    };

    try {
        const result = await axios.post("https://store.tseaudio.com/api/users/reset-password", { email });
        console.log("result:", result);

        if (result.status !== 200) {
            resetStatus.succeeded = false;
            resetStatus.errorMsg = result.data.errorMsg || "unknown error";
        }

    } catch (error) {
        resetStatus.succeeded = false;
        resetStatus.errorMsg = "internal error";
    }

    return resetStatus;
}

const ForgotPasswordPage = (props: any) => {
    const [ email, setEmail ] = useState("");
    const [ errorMsg, setErrorMsg ] = useState("");
    const [ completed, setCompleted ] = useState(false);
    const [ isWorkingOnIt, setIsWorking ] = useState(false);

    if (completed) {
        return (
            <Row className="content" style={{ paddingTop: "2em", paddingBottom: "2em", minHeight: "30em" }}>
                <Col>
                    <Row>
                        <Col>
                            <h2 className="tse-orange">Reset Password</h2>
                        </Col>
                    </Row>

                    <Row className="text-center">
                        <Col />
                        <Col>
                            <Alert color="warning">Your password reset-link has been sent to {email}.</Alert>
                        </Col>
                        <Col />
                    </Row>
                
                </Col>

            </Row>
        )
    }

    const requestResetLink = async () => {
        setIsWorking(true);

        if (! EmailValidator.validate(email)) {
            setErrorMsg("Wrong e-mail address format");
            setIsWorking(false);
            return;
        }

        setErrorMsg("");
        const status = await sendResetPasswordLink(email);
        setCompleted(status.succeeded);

        if (! completed) {
            setErrorMsg(status.errorMsg || "internal error");
        }
        setIsWorking(false);
    };

    return (
        <Row className="content" style={{ paddingTop: "2em", paddingBottom: "1em", minHeight: "30em" }}>
            <Col>
                <Row >
                    <Col>
                        <h2 className="tse-orange">Reset Password</h2>

                    Enter the e-mail address that you used when creating your TSE Audio account.<br />
                    After pressing the button below you will receive an e-mail with a link you need to
                    visit to set a new password. <br /><br />
                    Please check your spam folder if you haven't received a mail within a few minutes.
                </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{ marginBottom: "2em" }}>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                requestResetLink(); }
                            }>
                                <FormGroup>
                                    <InputGroup style={{ width: "50%" }}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-envelope" />
                                            </InputGroupText>
                                        </InputGroupAddon>

                                        <Input name="email"
                                            placeholder="your@email.com"
                                            value={email}
                                            onChange={
                                                (event) => {
                                                    setEmail(event.target.value);
                                                }
                                            }
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Form>

                            {
                                (errorMsg.length > 0) && (
                                    <Alert color="danger" style={{ width: "50%" }}>
                                        {errorMsg}
                                    </Alert>
                                )
                            }

                            <Button
                                className="btn-warning"
                                style={{ width: "50%" }}
                                disabled={completed || isWorkingOnIt}
                                onClick={requestResetLink}>
                                <i className="fas fa-unlock" /> Reset Password
                        </Button>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>

    );
}


export default ForgotPasswordPage;