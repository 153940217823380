import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button} from "reactstrap";

interface IPurchaseModalReturnType {
  modal: boolean,
  setModal: React.Dispatch<React.SetStateAction<boolean>>,
  toggle: () => void,
  component: JSX.Element
}

const PurchaseModal = (purchaseLink: string): IPurchaseModalReturnType => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const component = (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="">
        <ModalHeader toggle={toggle} className="tse-orange"><i className="fas fa-info-circle"></i> Purchase Information.</ModalHeader>
        <ModalBody>      
          <ul>
            <li>
            <p>
              We highly recommend our customers to test the TSE X50 v2 <span className="tse-orange">demo version</span> on your system before purchasing the product, 
              to ensure <b>compatibility</b> and that you know what you are buying before completing the purchase.
            </p>

            </li>
            <li>
            <p>
              After completing your purchase you will receive a <span className="tse-orange">license file</span> from FastSpring and an <span className="tse-orange">account</span> is created for you here at tseaudio.com.
            </p>
            </li>
            <li>
              Instructions on setting your <span className="tse-orange">account password</span> will be sent to your e-mail address.                    

            </li>
          </ul>

          <p>
            Thank you for purchasing TSE X50 v2! <br />- TSE Audio
          </p>
        </ModalBody>
        <ModalFooter>
          <a href="https://sites.fastspring.com/tseaudio/instant/X50v2SL">
          <Button className="btn-warning" style={{ color: "#000000" }}>
            <i className="fas fa-check-circle" /> CONTINUE TO FASTSPRING
          </Button>
          </a>

          <Button color="danger" onClick={toggle}>CANCEL</Button>
        </ModalFooter>
      </Modal>
    </div>
  );

  return { modal, setModal, toggle, component };
}

export default PurchaseModal;