import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import { GeneratedSystemRequirements } from "./SystemRequirements";
import { IProductRequirements } from "./Product";
import DonationForm from "./Donate";
import ProductJumbotron from "./ProductJumbotron";
import DownloadButton from "../buttons/downloadButton";

const minimumRequirements: IProductRequirements = {
  windows: {
    cpu: "Intel Core 2 or newer",
    operatingSystem: "Windows 7",
    daw: "32/64 bit compatible",
    pluginFormats: ["VST2", "AAX"],
  },
  macOS: {
    cpu: "Intel Core 2 or newer",
    operatingSystem: "OS X 10.8",
    daw: "32/64 bit compatible",
    pluginFormats: ["VST2", "AudioUnit", "AAX"],
  },
};

const BOD = () => {
  return (
    <Fragment>
    <Row className="content">
      <Col lg="12">
        <ProductJumbotron
          freeware={true}
          bottomBordered={false}
          productTitle="TSE BOD"
          productSubtitle="Bass Overdrive Plugin"
          productVersion="3.0"
          productImageUrl="/assets/images/products/BOD.png"
          productDescription={
            <div>
              The <b className="tse-orange">BOD</b> is emulating a well known bass processing unit. This plugin includes controls for
            overdrive, presence to add upper harmonic contents and definition, tone controls, and a blend control to mix clean signal with
            the processed signal.
            <br />
              <br />
            When you need a good bass tone quick, slap this on your track and you're already half-way there!
          </div>
          }

        />
      </Col>
    </Row>

    <Row>
      <Col lg="12">
        {/*==============================================================================================*/}
        {/* Product resources */}
        <Row className="content" style={{ paddingTop: "1em", paddingBottom: "1em" }}>

          <Col lg="3" style={{ margin: "10px" }}>
            <Row>
              <Col>
                <h5 className="tse-orange"><i className="fas fa-download" /> Download TSE BOD</h5>
              </Col>
            </Row>

            <Row style={{ marginTop: "1em" }}>
              <Col className="text-center">
                <DownloadButton
                  downloadType="windows"
                  titleFirst="Windows"
                  titleEnd="Installer"
                  to="/files/TSE_BOD_v3.0.0_Windows.zip"
                />
                <DownloadButton
                  downloadType="apple"
                  titleFirst="macOS"
                  titleEnd="Installer"
                  to="/files/TSE_BOD_v3.0.0_macOS.zip"
                />
              </Col>
            </Row>
            
          </Col>

            <Col style={{ margin: "10px" }}>
              <h5 className="tse-orange"><i className="fas fa-cog" /> System Requirements</h5>
              <GeneratedSystemRequirements Requirements={minimumRequirements} showTitle={false} />
            </Col>

            <Col lg="4" style={{ margin: "10px" }}>
              <Row>
                <Col>
                  <h5 className="tse-orange"><i className="fas fa-donate" /> Support future development</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  {DonationForm()}
                </Col>
              </Row>
            </Col>
          </Row>
      </Col>
    </Row>

    </Fragment>


  );
};

export default BOD;
