import React from "react";
import { FormGroup, Form, Input, Button } from "reactstrap";

const DonationForm = () => {
  return (
    <div>
      <p>
        Are you enjoying our freeware products?
        <br />
        You can support future freeware products and updates by buying the software creator a beer!
      </p>
      <Form inline action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" style={{ width: "90%", minWidth: "100px" }}>
        <input name="cmd" value="_s-xclick" type="hidden" />
        <input name="hosted_button_id" value="A7K78QGP8V38A" type="hidden" />
        <input name="on0" type="hidden" value="Give your support by buying the software creator a Beer!" />
        <input name="currency_code" value="USD" type="hidden" />

        <FormGroup>
          {/* <Label for="beerSelect"><i className="fas fa-beer" /> Select size</Label> */}
          <Input type="select" name="os0" id="os0">
            <option value="Small Beer">Small Beer $5 USD</option>
            <option value="Normal Beer">Normal Beer $10 USD</option>
            <option value="Large Beer">Large Beer $15 USD</option>
            <option value="Extra Large Beer">XXL Beer $20 USD</option>
            <option value="XXL Beer">XXXL Beer $30 USD</option>
          </Input>
          <Button outline color="warning" alt="PayPal - The safer, easier way to pay online!">
            Take me to PayPal <i className="fab fa-cc-paypal" />
          </Button>
          {/* <i className="fab fa-cc-paypal" style={{ fontSize: "40px" }}/> */}
        </FormGroup>
        <img alt="" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
      </Form>
    </div>
  );
};

export default DonationForm;
