import React from "react";
import { Col, Row } from "reactstrap";

class NotFound extends React.Component {
    public render() {
        return (
            <Row className="content text-center">
                <Col lg="3"/>
                <Col style={{ height: "300px", marginTop: "10em"}}>
                    <h1 className="tse-orange">404</h1>
                    <h3 className="tse-orange">Not Found</h3>
                    <div className="content">
                        Unfortunately, we cannot find the resource you are looking for!
                    </div>
                </Col>
                <Col lg="3"/>
            </Row>
        );
    }
}

export default NotFound;