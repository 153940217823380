import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { isSessionValid } from "./session";
import ScrollToTop from "./components/utils/scroll-to-top";

// import "./assets/theme/bootstrap.min.css";
import "./App.css";

import Home from "./components/home";
import Software from "./components/software";
import FAQ from "./components/faq";
import Contact from "./components/contact";
import NotFound from "./components/notfound";

import StyledNav from  "./components/navigation/navbar"
import Footer from "./components/navigation/footer";

import Account from "./components/auth/account";
import ForgotPassword from "./components/auth/forgot_password";
import ChangePassword from "./components/auth/change_password";
import Admin from "./components/auth/auth";
import { Container, Row, Col } from "reactstrap";

import { Helmet } from "react-helmet";



// interface IContentWrapper {
//   children?: any
// }
// const ContentWrapper: React.FunctionComponent = (options: IContentWrapper) => {
//   return (
//     <Row className="content">
//       <Col lg="12" style={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}>
//         {options.children}
//       </Col>
//     </Row>);
// };



export interface AppState {
  email: string;
  password: string;
  isRequesting: boolean;
  isLoggedIn: boolean;
  // data: App.Item[];
  error: string;
}

class WebsiteRouter extends React.Component<{}, AppState> {
  public state = {
    email: "",
    password: "",
    isRequesting: false,
    isLoggedIn: false,
    // data: [],
    error: "",
  };

  public componentDidMount() {
    this.setState({ isLoggedIn: isSessionValid() });
  }

  public render() {
    return (
      <Container fluid className="roboto-condensed" style={{ maxWidth: 1366 }}>
        {/* <Helmet>
          <title>TSE Audio - Home</title>
          <meta name="description" content="test on react-helmet" />
          <meta name="theme-color" content="#ccc" />
        </Helmet> */}
        <Router>
          <ScrollToTop />
          <StyledNav />

          <Row className="">
            
            <Col className="" style={{ paddingLeft: "2em", paddingRight: "2em" }}>
              <Switch>
                <Route exact path="/" component={Home} />

                <Route path="/software/:product">
                  <Software />
                </Route>

                <Route exact path="/contact" component={Contact} />
                <Route exact path="/faq" component={FAQ} />
                <Route exact path="/account" component={Account} />
                <Route exact path="/reset-password" component={ForgotPassword} />

                <Route path="/reset/:email/:resetPwdToken">
                  <ChangePassword />
                </Route>

                <Route exact path="/admin" component={Admin} />

                <Route component={NotFound} />
              </Switch>
            </Col>
          </Row>

          <Footer />
        </Router>
      </Container>
    );
  }
}

ReactDOM.render(<WebsiteRouter />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
