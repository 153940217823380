import axios from "axios";
import React, { Fragment } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";


enum SubjectCategory {
  General = 0,
  Support,
  Account,
  NumCategories,
}

interface IFormError {
  email?: string;
  name?: string;
  message?: string;
  category?: string;
  antispam?: string;
}

interface ContactState {
  name: string;
  email: string;
  message: string;
  category: number;
  antispam: number | null;

  validEmail: boolean;
  validName: boolean;
  validMessage: boolean;
  validCategory: boolean;
  validAntispam: boolean;

  formErrors: IFormError;
  formValid: boolean;

  messageSent: boolean;
  sendingMessage?: boolean;
}

class Contact extends React.Component<RouteComponentProps<any>, ContactState> {
  public constructor(props: any) {
    super(props);

    this.state = {
      name: "",
      email: "",
      message: "",
      category: -1,
      antispam: null,

      validEmail: false,
      validName: false,
      validMessage: false,
      validCategory: false,
      validAntispam: false,

      formValid: false,
      formErrors: {
        email: "",
        name: "",
        message: "",
        category: "",
        antispam: "",
      },

      messageSent: false,
      sendingMessage: false,
    };
  }

  public componentDidMount() {
    this.validateField("email", "");
    this.validateField("name", "");
    this.validateField("category", -1);
    this.validateField("antispam", -1);
    this.validateField("message", "");
  }

  public onSubmit = () => {
    this.props.history.push("/software");
  };

  public validateField(fieldName: any, value: any) {
    const fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.validEmail;
    let nameValid = this.state.validName;
    let messageValid = this.state.validMessage;
    let categoryValid = this.state.validCategory;
    let antispamValid = this.state.validAntispam;

    const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

    switch (fieldName) {
      case "email":
        emailValid = emailRegex.test(value);
        fieldValidationErrors.email = emailValid ? "" : " email is invalid.";
        break;

      case "name":
        nameValid = value.length >= 2;
        fieldValidationErrors.name = nameValid ? "" : " name is too short.";
        break;

      case "message":
        messageValid = value.length >= 5;
        fieldValidationErrors.message = messageValid ? "" : " message is too short.";
        break;

      case "category":
        categoryValid = value >= SubjectCategory.General && value < SubjectCategory.NumCategories;
        fieldValidationErrors.category = categoryValid ? "" : " you have not selected a message category.";
        break;

      case "antispam":
        antispamValid = value !== null && Number(value) === new Date().getFullYear();
        fieldValidationErrors.antispam = antispamValid ? "" : " wrong year entered in anti-spam.";
        break;

      default:
        break;
    }

    this.setState(
      {
        ...this.state,
        formErrors: fieldValidationErrors,
        validEmail: emailValid,
        validName: nameValid,
        validMessage: messageValid,
        validCategory: categoryValid,
        validAntispam: antispamValid,
      },
      this.validateForm
    );
  }

  public validateForm() {
    this.setState({
      formValid:
        this.state.validEmail && this.state.validName && this.state.validCategory && this.state.validMessage && this.state.validAntispam,
    });
  }

  public handleUserInput(e: any) {
    const name: any = e.target.name;
    const value: any = e.target.value;
    this.setState({ ...this.state, messageSent: false, [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  public async sendMessage(state: ContactState) {
    const message: any = {
      email: state.email,
      name: state.name,
      category: SubjectCategory[state.category],
      message: state.message,
    };

    this.setState({ ...this.state, sendingMessage: true });

    try {
      const response = await axios.post("https://store.tseaudio.com/api/contact", message);
      if (response.status === 200) {
        this.setState({ messageSent: true, sendingMessage: false });
      }
    } catch (error) {

    }
  }

  public render() {
    return (
      <Fragment>
        <Row className="content" style={{ paddingTop: "2em", paddingBottom: "2em" }}>
          <Col>
            <h2 className="tse-orange">Contact us</h2>

            <Form>
              <FormGroup>
                <Row style={{ paddingBottom: "10px" }}>
                  <Col xs="12">
                    <InputGroup size="md">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-tags" />
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        type="select"
                        name="category"
                        id="enquiryType"
                        value={this.state.category}
                        onChange={event => this.handleUserInput(event)}
                      >
                        <option /*selected={true}*/ value={-1}>Please choose a category...</option>
                        <option value={SubjectCategory.General}>General Enquiry</option>
                        <option value={SubjectCategory.Support}>Technical Support</option>
                        <option value={SubjectCategory.Account}>Account</option>
                      </Input>
                    </InputGroup>
                  </Col>
                </Row>
                <Row style={{ paddingBottom: "10px" }}>
                  <Col xs="12" style={{ color: "orange", fontSize: "small" }}>
                    {this.state.category - (SubjectCategory.Support as number) === 0
                      ? "Please provide your OS and DAW specs in support enquiries. Check out our FAQ which might have a solution for your issue."
                      : ""}
                  </Col>
                </Row>

                <Row style={{ paddingBottom: "10px" }}>
                  <Col xs="6">
                    <InputGroup size="md">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-user" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="name"
                        placeholder="Your name"
                        value={this.state.name}
                        onChange={(event: any) => this.handleUserInput(event)}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs="6">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        name="email"
                        placeholder="your@email.com"
                        value={this.state.email}
                        onChange={(event: any) => this.handleUserInput(event)}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row style={{ paddingTop: "1em" }}>
                  <Col xs="12">
                    <Label for="userMessage" className="tse-orange">
                      <b>Your Message</b>
                    </Label>
                    <Input
                      type="textarea"
                      name="message"
                      id="userMessage"
                      style={{ height: "200px" }}
                      value={this.state.message}
                      placeholder="Hi..."
                      onChange={event => this.handleUserInput(event)}
                    />
                  </Col>
                </Row>

                {/* <div className="panel panel-default" style={{ }}>
                            <FormErrors formErrors={this.state.formErrors} />
                        </div> */}

                <Row style={{ paddingBottom: "1em", paddingTop: "2em" }}>
                  <Col xs="6">
                    <b>Anti-spam:</b>
                    <br />
                    Please enter the year we are currently in...
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-calendar" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="2022?"
                        name="antispam"
                        value={this.state.antispam ? this.state.antispam : ""}
                        onChange={event => this.handleUserInput(event)}
                      />
                    </InputGroup>
                  </Col>

                  <Col xs="6">{/* <br /> */}</Col>
                </Row>
              </FormGroup>

              <p className="text-center" style={{ fontSize: "small" }}>
                {this.state.messageSent ? "Your message has been received. We will get back to you as soon as possible!" : ""}
                {this.state.formValid ? "" : "Please fill in all fields correctly before sending!"}
                {this.state.sendingMessage ? (
                  <div>
                    <br />
                    <Spinner />
                  </div>
                ) : (
                  ""
                )}
              </p>

              <Button
                className="btn-warning"
                style={{ width: "100%" }}
                disabled={!this.state.formValid || this.state.messageSent}
                onClick={() => this.sendMessage(this.state)}
              >
                <i className="fas fa-check" /> Submit
              </Button>
            </Form>

            <br />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default Contact;
