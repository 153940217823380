import React, { Fragment } from "react";
import { Row, Col, Card, CardTitle, CardText } from "reactstrap";
import { Link } from "react-router-dom";

const NewsItem = (props: { title: string, date: string, imageSrc?: string, content: string, readMoreLink?: string }) => {
    return (
        <Row style={{ marginBottom: "1em" }}>
            <Col>
                <Card body className="">
                    <CardTitle className="heading tse-orange" style={{ fontSize: "18px" }}>{props.title}</CardTitle>
                    {/* <CardSubtitle style={{ marginTop: "-15px", fontSize: "12px" }}>{ props.date }</CardSubtitle> */}

                    {
                        props.imageSrc &&
                        <div className="text-center">
                        <img className="" width="90%" src={props.imageSrc} alt="" style={{ border: "4px", borderColor: "#000"}}/>
                        </div>
                    }

                    <CardText style={{ marginTop: "12px", fontSize: "16px" }}>{props.content}</CardText>
                    <Link
                        to={props.readMoreLink ? props.readMoreLink : "#"}
                        className="text-right"
                        style={{ fontSize: "14px", paddingTop: "-10px" }}>
                        Read more
                    </Link>
                </Card>
            </Col>
        </Row>

    );
}


const News = () => {
    return (
        <Fragment>
            <Row style={{ padding: "0em" }}>
                <Col>

                    <h3 className="tse-orange">News</h3>

                    <NewsItem
                        title="TSE X50 v2.4.8"
                        date="April 28, 2018"
                        content="Release of TSE X50 v2.4.8."
                        imageSrc="/assets/images/products/X50/2.png"
                        readMoreLink="/software/X50"
                    />

                    <NewsItem
                        title="TSE BOD v3.0"
                        date="July 29, 2017"
                        content="Release of TSE BOD v3.0 for macOS and Windows."
                        imageSrc="/assets/images/products/BOD.png"
                        readMoreLink="/software/BOD"
                    />

                    <NewsItem
                        title="TSE R47 v1.0.1"
                        date="Febrary 14, 2014"
                        content="Release of TSE R47 v1.0.1 for macOS and Windows."
                        imageSrc="/assets/images/products/R47.jpg"
                        readMoreLink="/software/R47"
                    />

                </Col>

            </Row>
        </Fragment>
    );
}

export default News;